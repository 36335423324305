import { AUDIT_PROGRESS } from './audit'

const SECTIONS = {
  cancellation_condition: 'cancellation-condition',
  cancellation_torque_stick: 'cancellation-torque-stick',
  cancellation_reason: 'cancellation-reason',
  completed: AUDIT_PROGRESS.completed,
}

const SECTIONS_ORDER = {
  [SECTIONS.cancellation_condition]: SECTIONS.cancellation_torque_stick,
  [SECTIONS.cancellation_torque_stick]: SECTIONS.cancellation_reason,
  [SECTIONS.cancellation_reason]: SECTIONS.completed,
}

const QUESTIONS = {
  [SECTIONS.cancellation_condition]:
    'Did the technician loosen lugs or remove a wheel?',
  [SECTIONS.cancellation_torque_stick]:
    'Did the technician conduct a Courtesy Torque?',
  [SECTIONS.cancellation_reason]:
    'Did the technician take a picture of the OSC condition?',
}

const WHEEL_ISSUES = {
  noPhoto: 'No Photo',
  loosenedLugs: 'Loosened Lugs',
  removedWheel: 'Removed Wheel',
}

const TORQUE_STICK_ISSUES = {
  noCourtesyTorque: 'No Courtesy Torque',
  unconfirmedTorque: 'Unconfirmed Torque',
  noPhoto: 'No Photo',
  blurryBadPhoto: 'Blurry/Bad Photo',
  wrongPhoto: 'Wrong Photo',
  incorrectTorqueSetting: 'Incorrect Torque Setting',
  customerVehicleUnavailable: 'Customer Vehicle Unavailable',
}

const TECH_SUPPORT_ISSUES = {
  didNotCall: 'Technician did Not Call',
}

const CONDITION_ISSUES = {
  noPhoto: 'No Photo',
  blurryBadPhoto: 'Blurry/Bad Photo',
  wrongPhoto: 'Wrong Photo',
}

export {
  QUESTIONS,
  SECTIONS,
  SECTIONS_ORDER,
  WHEEL_ISSUES,
  TORQUE_STICK_ISSUES,
  TECH_SUPPORT_ISSUES,
  CONDITION_ISSUES,
}
