import { AUDIT_PROGRESS } from './audit'

const SECTIONS = {
  van_external_images: 'van_external_images',
  daily_validator: 'daily_validator',
  completed: AUDIT_PROGRESS.completed,
}

const TIME_ISSUES = {
  completedLate: 'Completed after 10AM',
}

const VAN_ISSUES = {
  incorrectVan: 'Incorrect Van',
  damageIdentified: 'Damage Identified - needs review',
}

const DAILY_VALIDATOR_ISSUES = {
  unableToReadScreen: 'Unable to Read Screen',
  wrongPhoto: 'Wrong Photo',
  redLight: 'Red Light',
  broken: 'Broken',
  outOfCalibration: 'Out of Calibration (±10%)',
  noPhoto: 'No Photo / Black Photo',
}

const SECTIONS_ORDER = {
  [SECTIONS.van_external_images]: SECTIONS.daily_validator,
  [SECTIONS.daily_validator]: SECTIONS.completed,
}

const TITLES = {
  [SECTIONS.van_external_images]: 'DVIR Van',
  [SECTIONS.daily_validator]: 'Daily Validator',
}

const QUESTIONS = {
  [SECTIONS.van_external_images]: 'Exterior photos.',
  [SECTIONS.daily_validator]:
    'Did the technician record a pass of the Mountz validator?',
}

export {
  TIME_ISSUES,
  VAN_ISSUES,
  DAILY_VALIDATOR_ISSUES,
  QUESTIONS,
  SECTIONS,
  SECTIONS_ORDER,
  TITLES,
}
