import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import { ITechnician } from '../../../../../../app/entities/Technician'
import { getISOWeekNumber } from '../../../../global/utils/date/get-iso-week-number'
import { WeeklyDvirTable } from './components/WeeklyDvirDashboardTable'
import { DailyDvirTable } from './components/DailyDvirDashboardTable'
import { STATUS } from '../../../../global/types/audit'

interface IInstallationAuditModalProps {
  isOpen: boolean
  setIsOpen: Function
  technician: ITechnician | null
}

export default function DvirAuditModal(props: IInstallationAuditModalProps) {
  const { isOpen, setIsOpen, technician } = props
  if (!technician) return <> </>
  const weekNumber = getISOWeekNumber(new Date())
  const weeklyDvirAudits = technician.audit?.filter(
    (audit) => audit.status === STATUS.WEEKLY_DVIR,
  )
  const dailyDvirAudits = technician.audit?.filter(
    (audit) => audit.status === STATUS.DAILY_DVIR,
  )

  async function handleClosed(): Promise<void> {
    setIsOpen(false)
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => handleClosed()}
    >
      <Box className={styles.auditModal}>
        <div className={styles.modalTitle}>
          <span style={{ fontWeight: 700 }}>{technician.name}:</span> Week{' '}
          {weekNumber}
        </div>
        <div className={styles.tableContaier}>
          <div>
            {dailyDvirAudits && dailyDvirAudits.length > 0 && (
              <DailyDvirTable audits={dailyDvirAudits} />
            )}
          </div>
          <div style={{ marginTop: '20px' }}>
            {weeklyDvirAudits && weeklyDvirAudits.length > 0 && (
              <WeeklyDvirTable audits={weeklyDvirAudits} />
            )}
          </div>
        </div>
      </Box>
    </Modal>
  )
}
