import React from 'react'
import Radio from '@mui/material/Radio'
import styles from './styles.module.css'

interface IPrimaryRadioProps {
  checked: boolean
  label?: string
  className?: string
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  name?: string
  style?: React.CSSProperties
  disabled?: boolean
}

function getRadioStyles(radioOptions: IPrimaryRadioProps) {
  return {
    color: '#D70000',
    '& .MuiSvgIcon-root': { fontSize: 28 },
    '&.Mui-selected': {
      color: radioOptions.disabled ? '' : '#D70000',
    },
    '&:hover': { backgroundColor: 'transparent' },
  }
}

export default function PrimaryRadio(props: IPrimaryRadioProps) {
  function onLabelClick() {
    if (props.onChange) {
      props.onChange(
        { target: { checked: !props.checked } } as any,
        !props.checked,
      )
    }
  }

  return (
    <div
      className={`
        ${styles.radioWrapper}
        ${props.className}
        ${props.disabled ? styles.disabledTextColor : styles.enabledTextColor}
      `}
    >
      <Radio
        disabled={props.disabled}
        sx={getRadioStyles(props)}
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
        style={props.style}
      />
      <div
        className={props.disabled ? '' : styles.label}
        onClick={props.disabled ? () => {} : onLabelClick}
      >
        {props.label}
      </div>
    </div>
  )
}
