import styles from './styles.module.css'
import logo from '../assets/images/trmi-logos/prod.png'

export default function TermsPage() {
  return (
    <div className={styles.termsWrapper}>
      <div className={styles.logoContainer}>
        <img
          src={logo}
          alt='Tire Rack Logo'
          className={styles.logo}
        />
      </div>
      <div className={styles.termsContainer}>
        <p>
          Thank you for opting in to receive text messages from{' '}
          <strong>Tire Rack Mobile Installation</strong>! By opting in, you
          agree to receive text messages from Tire Rack Mobile Installation at
          the phone number you provided. Message and data rates may apply.
          Message frequency varies. Reply <strong>HELP</strong> for help or{' '}
          <strong>STOP</strong> to cancel.
        </p>
        <p>
          View our{' '}
          <a href='https://www.tirerack.com/about/privacy.jsp'>
            Privacy Policy
          </a>
          ,{' '}
          <a href='https://www.tirerack.com/content/tirerack/desktop/en/customer_support/terms_of_use.html'>
            Terms of Use
          </a>
          , and{' '}
          <a href='https://www.tirerack.com/content/tirerack/desktop/en/customer_support/sms_terms_conditions.html'>
            SMS Terms and Conditions
          </a>{' '}
          for more information.
        </p>
      </div>
    </div>
  )
}
