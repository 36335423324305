import { STATUS } from '../../utils/get-statuses-for-audit-section'
import styles from './styles.module.css'

function StatusDot(status: string): JSX.Element {
  switch (status) {
    case STATUS.pass:
      return <div className={styles.green}></div>
    case STATUS.coached:
      return <div className={styles.blue}></div>
    case STATUS.fail:
      return <div className={styles.red}></div>
    default:
      return <div className={styles.blank}></div>
  }
}

export { StatusDot }
