import { useEffect, useState } from 'react'
import { Column } from 'react-table'
import Table from '../../../global/components/table/Table'
import { IAudit } from '../../../../../app/entities/Audit'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import NoFilter from '../../../global/components/table/helpers/NoFilter'
import { ILocationFilters } from '../../main/DashboardsMain'
import { AUDIT_PROGRESS } from '../../../global/constants/audit'
import { ITechnician } from '../../../../../app/entities/Technician'
import { STATUS } from '../../../global/types/audit'
import InstallationAuditModal from './modal/InstallationAuditModal'
import { DeepPartial } from 'typeorm'

/**
 * This function is a fake ITechnician object forced into a mold so that it can show up in this table
 */
function getOverallScore(tableData: ITechnician[]): DeepPartial<ITechnician> {
  let allAudits: IAudit[] = []
  tableData.forEach((technician) => {
    const { audit } = technician
    allAudits = [...allAudits, ...(audit ?? [])]
  })
  const summaryRowTechnician: DeepPartial<ITechnician> = {
    name: '',
    location: {
      name: 'Overall Score',
    },
    audit: allAudits,
  }
  return summaryRowTechnician
}

interface IInstallationAuditsTableProps {
  date: Date
  setRefetchIndex: Function
  refetchIndex: number
  locationFilters: ILocationFilters
}

function InstallationAuditsTable(props: IInstallationAuditsTableProps) {
  const { setRefetchIndex, refetchIndex, locationFilters, date } = props
  const [technician, setTechnician] = useState<ITechnician | null>(null)
  const [installationAuditModalOpen, setInstallationAuditModalOpen] =
    useState(false)
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    offSet: 0,
    pageSize: 100,
    count: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})
  const endpoint = `${
    process.env.REACT_APP_ORIGIN
  }/technicians/installation/dashboard?queryDate=${
    date.toISOString().split('T')[0]
  }&` //instead of adding in a queryDate to the retrieve table, we are going to sneak it into the endpoint

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  function retrieveTableData() {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      columnFilters,
      selectedLocationId: locationFilters.locationFilter,
    })
  }

  useEffect(() => {
    retrieveTableData()
  }, [refetchIndex, locationFilters, tableState.pageSize, tableState.offSet])

  function handleRowClick(row: any) {
    setTechnician(row.original as ITechnician)
    setInstallationAuditModalOpen(true)
  }

  const columns: Column<ITechnician | any>[] = [
    {
      Header: 'TRMI Location',
      accessor: 'location.name',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ location: { name: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Technician',
      accessor: 'name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ name: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Total',
      accessor: 'totalNumberOfAudits',
      Filter: NoFilter(),
      Cell: ({ row: { original } }) => {
        const { audit } = original as ITechnician
        const totalNumberOfAudits = audit?.length
        return <>{totalNumberOfAudits ?? '-'}</>
      },
    },
    {
      Header: 'Passed',
      accessor: 'passedPercentAudits',
      Filter: NoFilter(),
      Cell: ({ row: { original } }) => {
        const { audit } = original as ITechnician
        const totalNumberOfAudits = Number(audit?.length)
        const totalNumberOfPassedAudits = Number(
          audit?.filter(
            (audit: IAudit) => audit.progress === AUDIT_PROGRESS.completed,
          ).length,
        )
        const percentage =
          Number(totalNumberOfPassedAudits / totalNumberOfAudits) * 100
        const isPercentageNaN = Number.isNaN(percentage)
        return <>{isPercentageNaN ? '-' : `${percentage.toFixed(2)}%`}</>
      },
    },
    {
      Header: 'OSC Total',
      accessor: 'totalNumberOfOscAudits',
      Filter: NoFilter(),
      Cell: ({ row: { original } }) => {
        const { audit } = original as ITechnician
        const oscAudits = audit?.filter(
          (audit: IAudit) => audit.status === STATUS.ON_SITE_CANCELLATION,
        )
        const totalNumberOfOscAudits = oscAudits?.length
        return <>{totalNumberOfOscAudits ?? '-'}</>
      },
    },
    {
      Header: 'OSC Passed',
      accessor: 'passedPercentOscAudits',
      Filter: NoFilter(),
      Cell: ({ row: { original } }) => {
        const { audit } = original as ITechnician
        const oscAudits = audit?.filter(
          (audit: IAudit) => audit.status === STATUS.ON_SITE_CANCELLATION,
        )
        const totalNumberOfOscAudits = Number(oscAudits?.length)
        const totalNumberOfPassedOscAudits = Number(
          oscAudits?.filter(
            (audit: IAudit) => audit.progress === AUDIT_PROGRESS.completed,
          ).length,
        )
        const percentage =
          Number(totalNumberOfPassedOscAudits / totalNumberOfOscAudits) * 100
        const isPercentageNaN = Number.isNaN(percentage)
        return <>{isPercentageNaN ? '-' : `${percentage.toFixed(2)}%`}</>
      },
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <InstallationAuditModal
        isOpen={installationAuditModalOpen}
        setIsOpen={setInstallationAuditModalOpen}
        technician={technician}
      />
      <Table
        columns={columns}
        data={
          tableState.data.length > 0
            ? [...tableState.data, getOverallScore(tableState.data)]
            : []
        }
        onRowClick={handleRowClick}
        isLoading={tableState.isLoading}
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default InstallationAuditsTable
