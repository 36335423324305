import {
  SECTIONS as COMPLETED_INSTALLATION_SECTIONS,
  SECTIONS_ORDER as COMPLETED_INSTALLATION_SECTIONS_ORDER,
} from '../../global/constants/audit-completed-installation'
import {
  SECTIONS as ON_SITE_CANCELLATION_SECTIONS,
  SECTIONS_ORDER as ON_SITE_CANCELLATION_SECTIONS_ORDER,
} from '../../global/constants/audit-on-site-cancellation'
import {
  SECTIONS as DAILY_DVIR_SECTIONS,
  SECTIONS_ORDER as DAILY_DVIR_SECTIONS_ORDER,
} from '../../global/constants/audit-daily-dvir'
import {
  SECTIONS as WEEKLY_DVIR_SECTIONS,
  SECTIONS_ORDER as WEEKLY_DVIR_SECTIONS_ORDER,
} from '../../global/constants/audit-weekly-dvir'
import { IAuditState, STATUS } from '../../global/types/audit'
import { AUDIT_PROGRESS } from '../../global/constants/audit'

function getNextSection(
  currentSection: string,
  auditState: IAuditState | null,
  auditStatus: STATUS | null,
): string {
  let nextSection: string = currentSection
  switch (auditStatus) {
    case STATUS.COMPLETED_INSTALLATION: {
      nextSection = COMPLETED_INSTALLATION_SECTIONS_ORDER[currentSection]
      break
    }
    case STATUS.ON_SITE_CANCELLATION: {
      nextSection = ON_SITE_CANCELLATION_SECTIONS_ORDER[currentSection]
      break
    }
    case STATUS.DAILY_DVIR: {
      nextSection = DAILY_DVIR_SECTIONS_ORDER[currentSection]
      break
    }
    case STATUS.WEEKLY_DVIR: {
      nextSection = WEEKLY_DVIR_SECTIONS_ORDER[currentSection]
      break
    }
  }
  if (nextSection === AUDIT_PROGRESS.completed && auditState) {
    const auditNeedsCoaching = Object.values(auditState).some(
      (section) => section.coaching.needsCoaching,
    )
    if (auditNeedsCoaching) {
      nextSection = AUDIT_PROGRESS.needsCoaching
    }
  }
  return nextSection
}

function getPreviousSectionInOrder(
  currentSection: string,
  initialSection: string,
  sectionsOrder: { [key: string]: string },
) {
  const previousSection =
    Object.entries(sectionsOrder).find(
      ([_, value]) => value === currentSection,
    )?.[0] || initialSection
  return previousSection
}

function getPreviousSection(
  currentSection: string,
  auditStatus: STATUS | null,
) {
  let initialSection: string = ''
  let sectionsOrder: { [key: string]: string } = {}
  switch (auditStatus) {
    case STATUS.COMPLETED_INSTALLATION: {
      initialSection =
        COMPLETED_INSTALLATION_SECTIONS.pre_install_front_passenger
      sectionsOrder = COMPLETED_INSTALLATION_SECTIONS_ORDER
      break
    }
    case STATUS.ON_SITE_CANCELLATION: {
      initialSection = ON_SITE_CANCELLATION_SECTIONS.cancellation_condition
      sectionsOrder = ON_SITE_CANCELLATION_SECTIONS_ORDER
      break
    }
    case STATUS.DAILY_DVIR: {
      initialSection = DAILY_DVIR_SECTIONS.van_external_images
      sectionsOrder = DAILY_DVIR_SECTIONS_ORDER
      break
    }
    case STATUS.WEEKLY_DVIR: {
      initialSection = WEEKLY_DVIR_SECTIONS.front_of_van
      sectionsOrder = WEEKLY_DVIR_SECTIONS_ORDER
      break
    }
  }
  return getPreviousSectionInOrder(
    currentSection,
    initialSection,
    sectionsOrder,
  )
}

export { getNextSection, getPreviousSection }
