import { DAYS_OF_WEEK } from '../../constants/date'

function isDayOfWeek(date: Date, day: (typeof DAYS_OF_WEEK)[number]): boolean {
  const newDate = new Date(date)
  //because getUTCDay() returns 0 for Sunday, 1 for Monday, etc.
  //we have to do the following gross thing...
  //we subtract one to get a zero index, then add seven to get a positive index,
  //then mod by seven to get the correct index... -\_(:/)_/-
  const dayOfWeekIndex = (newDate.getUTCDay() + 6) % 7
  const dayOfWeek = DAYS_OF_WEEK[dayOfWeekIndex]
  return dayOfWeek === day
}

export { isDayOfWeek }
