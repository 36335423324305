import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Column } from 'react-table'
import Table from '../../../global/components/table/Table'
import { IAudit } from '../../../../../app/entities/Audit'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import NoFilter from '../../../global/components/table/helpers/NoFilter'
import { ILocationFilters } from '../../main/DashboardsMain'
import { isDayOfWeek } from '../../../global/utils/date/get-day-of-week'
import { DAYS_OF_WEEK } from '../../../global/constants/date'
import { AUDIT_PROGRESS } from '../../../global/constants/audit'
import { ITechnician } from '../../../../../app/entities/Technician'
import DvirAuditModal from './modal/DvirAuditModal'

interface IDvirAuditsTableProps {
  date: Date
  setRefetchIndex: Function
  refetchIndex: number
  locationFilters: ILocationFilters
}

function getStatusDot(progress: string | undefined) {
  switch (progress) {
    case AUDIT_PROGRESS.completed:
      return <div className={styles.green}></div>
    case AUDIT_PROGRESS.coached:
      return <div className={styles.blue}></div>
    case AUDIT_PROGRESS.needsCoaching:
      return <div className={styles.red}></div>
    default:
      return <div className={styles.blank}></div>
  }
}

function DvirAuditsTable(props: IDvirAuditsTableProps) {
  const { setRefetchIndex, refetchIndex, locationFilters, date } = props
  const [technician, setTechnician] = useState<ITechnician | null>(null)
  const [dvirAuditModalOpen, setDvirAuditModalOpen] = useState(false)
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    offSet: 0,
    pageSize: 100,
    count: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})
  const endpoint = `${
    process.env.REACT_APP_ORIGIN
  }/technicians/dvir/dashboard?queryDate=${date.toISOString().split('T')[0]}&`

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  function retrieveTableData() {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      columnFilters,
      selectedLocationId: locationFilters.locationFilter,
    })
  }

  useEffect(() => {
    retrieveTableData()
  }, [refetchIndex, locationFilters, tableState.pageSize, tableState.offSet])

  function handleRowClick(row: any) {
    setTechnician(row.original as ITechnician)
    setDvirAuditModalOpen(true)
  }

  const columns: Column<ITechnician | any>[] = [
    {
      Header: 'TRMI Location',
      accessor: 'location.name',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ location: { name: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Technician',
      accessor: 'name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ name: val }),
        onEnter: retrieveTableData,
      }),
    },
    ...DAYS_OF_WEEK.map((day) => ({
      Header: day,
      accessor: day,
      Filter: NoFilter(),
      Cell: ({ row: { original } }: { row: { original: any } }) => {
        const { audit } = original as ITechnician
        const dayAudits =
          audit?.filter((audit: IAudit) =>
            isDayOfWeek(audit.operationDate, day),
          ) ?? []
        return <>{getStatusDot(dayAudits[0]?.progress)}</>
      },
    })),
  ]

  return (
    <div style={{ width: '100%' }}>
      <DvirAuditModal
        isOpen={dvirAuditModalOpen}
        setIsOpen={setDvirAuditModalOpen}
        technician={technician}
      />
      <Table
        columns={columns}
        data={tableState.data ?? []}
        onRowClick={handleRowClick}
        isLoading={tableState.isLoading}
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default DvirAuditsTable
