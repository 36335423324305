import styles from './styles.module.css'
import PrimaryRadio from '../../../../global/components/radio-buttons/RadioButton'
import { IAuditOption } from '../../../../global/types/audit'

interface RadioButtonsDisplayProps {
  setOptionsState: Function
  optionsState: IAuditOption
}

function RadioButtonsDisplay({
  setOptionsState,
  optionsState,
}: RadioButtonsDisplayProps) {
  function handleClick(optionKey: string) {
    const newOptions = { ...optionsState }
    for (const key in newOptions) {
      newOptions[key].selection = false
    }
    newOptions[optionKey].selection = true
    setOptionsState(newOptions)
  }
  return (
    <div className={styles.radioContainer}>
      {Object.entries(optionsState).map(([key, value]) => (
        <PrimaryRadio
          key={key}
          checked={value.selection}
          label={key}
          onChange={() => handleClick(key)}
        />
      ))}
    </div>
  )
}

export default RadioButtonsDisplay
