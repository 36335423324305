/**
 * @param date
 * @returns MM/DD/YYYY
 */
export function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }

  return new Intl.DateTimeFormat('en-US', options).format(date)
}
