import { TextField } from '@mui/material'
import { MS_IN_DAY } from '../../../global/constants/date'
import { formatDateYyyyMmDd } from '../../../global/utils/date/date-utils-yyyy-mm-dd'
import styles from './styles.module.css'
import WeeksDropdown from '../dropdowns/WeeksDropDown'
import { useEffect, useState } from 'react'
import LocationDropDown from '../../../global/components/location-drop-down/LocationDropDown'
import { getMondayOfISOWeek } from '../../utils/get-date-of-monday-of-week-number'
import { getISOWeekNumber } from '../../../global/utils/date/get-iso-week-number'
import NavigationButton from '../../../global/components/buttons/navigation-button/NavigationButton'

interface IDashboardHeaderProps {
  queryDate: Date
  setQueryDate: Function
  setLocationFilter: Function
  seeWeeksDropdown?: boolean
  seeLocationDropdown?: boolean
}
export default function DashboardMainHeader(props: IDashboardHeaderProps) {
  const {
    queryDate,
    setQueryDate,
    seeWeeksDropdown,
    seeLocationDropdown,
    setLocationFilter,
  } = props
  const [weeks, setWeeks] = useState(getISOWeekNumber(new Date()))

  function setDate(input: 'prev' | 'next') {
    const shouldSubtractOneDay = input === 'prev'
    const msOffset = (shouldSubtractOneDay ? -1 : 1) * MS_IN_DAY
    setQueryDate(new Date(queryDate.getTime() + msOffset))
  }

  function handleDateChange(e: any) {
    const { value } = e.target
    setQueryDate(new Date(value))
  }

  useEffect(() => {
    if (seeWeeksDropdown) {
      const date = getMondayOfISOWeek(weeks)
      setQueryDate(date)
    } else {
      const today = new Date()
      setQueryDate(today)
    }
  }, [seeWeeksDropdown, weeks])

  return (
    <div className={styles.dashboardHeaderContainer}>
      <div className={styles.inputsContainer}>
        {seeLocationDropdown && (
          <LocationDropDown
            onLocationChange={(val: string) => {
              setLocationFilter(val)
            }}
          />
        )}
        {seeWeeksDropdown ? (
          <WeeksDropdown
            setWeeks={setWeeks}
            weeks={weeks}
            queryDate={queryDate}
            setQueryDate={setQueryDate}
          />
        ) : (
          <div className={styles.navButtonContainer}>
            <NavigationButton
              direction='back'
              handleClick={() => setDate('prev')}
            />
            <TextField
              label='Select a Date'
              type='date'
              value={formatDateYyyyMmDd(queryDate)}
              InputLabelProps={{ shrink: true }}
              sx={{ width: 130 }}
              onChange={handleDateChange}
            />
            <NavigationButton
              direction='forward'
              handleClick={() => setDate('next')}
            />
          </div>
        )}
      </div>
    </div>
  )
}
