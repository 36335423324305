import { useState } from 'react'
import { Column } from 'react-table'
import { IAudit } from '../../../../../../../app/entities/Audit'
import TableTitle from '../../../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../../../global/components/table/helpers/table-topper/TableTopper'
import Table from '../../../../../global/components/table/Table'
import { SECTIONS } from '../../../../../global/constants/audit-daily-dvir'
import { formatDate } from '../../../../../global/utils/date/mm-dd-yyyy'
import getStatusForAuditSection from '../../../../utils/get-statuses-for-audit-section'
import { StatusDot } from '../../../common/StatusDot'

function DailyDvirTable({ audits }: { audits: IAudit[] }) {
  const [isOpen, setIsOpen] = useState(true)

  const dailyDvircolumns: Column<IAudit | any>[] = [
    {
      Header: 'DVIR Date',
      accessor: 'operationDate',
      Cell: ({ row: { original } }) => {
        const date = new Date(original.operationDate)
        const formattedDate = formatDate(date)
        return <>{formattedDate}</>
      },
    },
    {
      Header: 'Van Name',
      accessor: 'dvir.van.name',
    },
    {
      Header: 'Damage',
      accessor: 'vanExternalImages',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failure = getStatusForAuditSection(state, [
          SECTIONS.van_external_images,
        ])
        return StatusDot(failure)
      },
    },
    {
      Header: 'Torque Validator',
      accessor: 'torqueValidator',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failure = getStatusForAuditSection(state, [
          SECTIONS.daily_validator,
        ])
        return StatusDot(failure)
      },
    },
  ]
  return (
    <div style={{ width: '100%' }}>
      <TableTopper
        isOpen={isOpen}
        onToggleClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Daily DVIRs</TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={dailyDvircolumns}
        data={audits}
        isLoading={false}
        onRowClick={() => {}}
        isOpen={isOpen}
        isDisplayMode={true}
      />
    </div>
  )
}

export { DailyDvirTable }
