enum VAN_IMAGE_TYPES {
  qr_code = 'qr_code',
}

enum SMALL_PART_SUPPLIES_IMAGE_TYPES {
  qr_code = 'qr_code',
}

//! match with image.ts in frontend/src/global/constants
const PRE_INSTALL_IMAGE_TYPES = {
  pre_front_driver: 'pre_front_driver',
  pre_front_driver_damage: 'pre_front_driver_damage',
  pre_front_passenger: 'pre_front_passenger',
  pre_front_passenger_damage: 'pre_front_passenger_damage',
  pre_rear_passenger: 'pre_rear_passenger',
  pre_rear_passenger_damage: 'pre_rear_passenger_damage',
  pre_rear_driver: 'pre_rear_driver',
  pre_rear_driver_damage: 'pre_rear_driver_damage',
  pre_vin: 'pre_vin',
  pre_additional: 'pre_additional',
}
const ORDER_DETAILS_IMAGE_TYPES = {
  torque_wrench_setting: 'torque_wrench_setting',
  first_lug_first_torque: 'first_lug_first_torque',
  left_work_area: 'left_work_area',
  right_work_area: 'right_work_area',
}
const POST_INSTALL_IMAGE_TYPES = {
  post_front_driver: 'post_front_driver',
  post_front_passenger: 'post_front_passenger',
  post_rear_passenger: 'post_rear_passenger',
  post_rear_driver: 'post_rear_driver',
  pre_wiper_windshield: 'pre_wiper_windshield',
  post_wiper_windshield: 'post_wiper_windshield',
  post_additional: 'post_additional',
}
const CANCEL_INSTALL_IMAGE_TYPES = {
  cancellation: 'cancellation',
}

const REQUIRED_INSTALLATION_IMAGE_BY_TYPE: string[] = [
  PRE_INSTALL_IMAGE_TYPES.pre_front_driver,
  PRE_INSTALL_IMAGE_TYPES.pre_front_passenger,
  PRE_INSTALL_IMAGE_TYPES.pre_rear_driver,
  PRE_INSTALL_IMAGE_TYPES.pre_rear_passenger,
  PRE_INSTALL_IMAGE_TYPES.pre_vin,
  ORDER_DETAILS_IMAGE_TYPES.first_lug_first_torque,
  ORDER_DETAILS_IMAGE_TYPES.torque_wrench_setting,
  POST_INSTALL_IMAGE_TYPES.post_front_driver,
  POST_INSTALL_IMAGE_TYPES.post_front_passenger,
  POST_INSTALL_IMAGE_TYPES.post_rear_driver,
  POST_INSTALL_IMAGE_TYPES.post_rear_passenger,
  POST_INSTALL_IMAGE_TYPES.pre_wiper_windshield,
  POST_INSTALL_IMAGE_TYPES.post_wiper_windshield,
  CANCEL_INSTALL_IMAGE_TYPES.cancellation,
]

export {
  VAN_IMAGE_TYPES,
  SMALL_PART_SUPPLIES_IMAGE_TYPES,
  PRE_INSTALL_IMAGE_TYPES,
  ORDER_DETAILS_IMAGE_TYPES,
  POST_INSTALL_IMAGE_TYPES,
  CANCEL_INSTALL_IMAGE_TYPES,
  REQUIRED_INSTALLATION_IMAGE_BY_TYPE,
}
