/**
 * @description This function takes the response(s) provided to the question
 * from the audit and turns them into a string to be displayed in the
 * coaching modal.
 * @param response
 * @returns
 */
function composeResponseString(response: {
  [key: string]: string | boolean
}): string {
  let answer: string[] = []
  let selected: string[] = []
  const string = Object.entries(response).map(([key, value]) => {
    if (typeof value === 'string') {
      const valueExists = value.length > 0
      answer.push(valueExists ? value : 'N/A')
    } else {
      selected.push(key)
    }
  })
  const selectedString =
    selected.length > 0 ? `Selected: ${selected.join(', ')}` : undefined
  const answerString =
    answer.length > 0 ? `Answer: ${answer.join(', ')}, ` : undefined
  return [answerString, selectedString].join(' ')
}

export { composeResponseString }
