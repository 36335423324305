import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import styles from './styles.module.css'

interface NavigationButtonProps {
  direction: 'forward' | 'back'
  handleClick: () => void
}

function NavigationButton({ direction, handleClick }: NavigationButtonProps) {
  const isForward = direction === 'forward'
  return (
    <button
      className={styles.navButton}
      onClick={handleClick}
    >
      {isForward ? (
        <ArrowForwardIosIcon
          style={{
            transform: 'scaleY(2)',
            shapeRendering: 'geometricPrecision',
            fontSize: 'large',
          }}
        />
      ) : (
        <ArrowBackIosIcon
          style={{
            transform: 'scaleY(2)',
            shapeRendering: 'geometricPrecision',
            fontSize: 'large',
          }}
        />
      )}
    </button>
  )
}

export default NavigationButton
