import { ChangeEvent, useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import LocationDropDown from '../../../../../global/components/location-drop-down/LocationDropDown'
import useFetch from '../../../../../global/hooks/useFetchHook'
import { ITechFormProps } from '../container-modal/TechnicianEdit'
import { getLocations } from '../../../../../global/api'
import { ILocation } from '../../../../../../../app/entities/Location'
import { getLocationByLocationId } from '../../api'
import { fetchData } from '../../../../../global/utils/fetch'
import hasPermission from '../../../../../global/utils/user/has-permission'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../../global/constants/permissions'
interface ITechInputsProps {
  formInfo: ITechFormProps
}
export default function TechInputBar(props: ITechInputsProps) {
  const { user } = useContext(UserContext)

  const [shouldAllowLocationEdit, setShouldAllowLocationEdit] =
    useState<boolean>(false)
  const { formInfo } = props
  const { technicianFormInfo, setTechnicianFormInfo } = formInfo
  const { employeeId, samsaraId, inactive } = technicianFormInfo

  const updateTechForm = (e: ChangeEvent<HTMLInputElement>) => {
    setTechnicianFormInfo({
      ...technicianFormInfo,
      [e.target.name]: e.target.value,
    })
  }
  async function updateLocation(str: string) {
    const location = await fetchData<ILocation>(getLocationByLocationId(str))
    const {
      workDayFrom,
      workDayTo,
      lunchFrom,
      lunchTo,
      lunchDuration,
      routingAlgorithm: locationRoutingAlgorithm,
    } = location
    setTechnicianFormInfo({
      ...technicianFormInfo,
      locationId: str,
      locationRoutingAlgorithm,
      defaultWorkDayFrom: workDayFrom,
      defaultWorkDayTo: workDayTo,
      defaultLunchFrom: lunchFrom,
      defaultLunchTo: lunchTo,
      defaultLunchDuration: lunchDuration,
    })
  }

  function activeStatusHandler(newStatus: boolean) {
    setTechnicianFormInfo({ ...technicianFormInfo, inactive: newStatus })
  }
  const { data } = useFetch<ILocation[]>(getLocations())

  useEffect(() => {
    if (data && technicianFormInfo.locationId) {
      let defaultHours = data.filter(
        (location: any) => location.objectId === technicianFormInfo.locationId,
      )[0]

      if (defaultHours) {
        setTechnicianFormInfo((prevInfo: any) => ({
          ...prevInfo,
          defaultWorkDayFrom: defaultHours.workDayFrom,
          defaultWorkDayTo: defaultHours.workDayTo,
          defaultLunchFrom: defaultHours.lunchFrom,
          defaultLunchTo: defaultHours.lunchTo,
          defaultLunchDuration: defaultHours.lunchDuration,
        }))
      }
    }

    if (technicianFormInfo.useDefaultWorkDay) {
      setTechnicianFormInfo(
        (prevInfo: {
          defaultWorkDayFrom: string
          defaultWorkDayTo: string
        }) => ({
          ...prevInfo,
          workDayFrom: prevInfo.defaultWorkDayFrom,
          workDayTo: prevInfo.defaultWorkDayTo,
        }),
      )
    }
  }, [
    data,
    technicianFormInfo.useDefaultLunch,
    technicianFormInfo.useDefaultWorkDay,
  ])

  useEffect(() => {
    const hasEditTechnicianLocationPermissions = hasPermission(user).allow(
      PERMISSIONS.TECHNICIANS.CATEGORY,
      PERMISSIONS.TECHNICIANS.ENTRIES.EDIT_LOCATION.NAME,
    )

    const isCreatingNewTechnician = technicianFormInfo.technicianId === ''

    setShouldAllowLocationEdit(
      hasEditTechnicianLocationPermissions || isCreatingNewTechnician,
    )
  }, [technicianFormInfo.technicianId, user])

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.flexEnd}>
          <TextField
            label='Employee Id'
            name='employeeId'
            value={employeeId || ''}
            sx={{ width: 150 }}
            onChange={updateTechForm}
          />
          <TextField
            label='Samsara Id'
            name='samsaraId'
            type='number'
            value={samsaraId || ''}
            sx={{ width: 150 }}
            onChange={updateTechForm}
          />
          <LocationDropDown
            disabled={!shouldAllowLocationEdit}
            defaultValue={technicianFormInfo.locationId}
            onLocationChange={(location: string) => updateLocation(location)}
          />
        </div>
        <div className={styles.btnsContainer}>
          {inactive ? (
            <button
              className={styles.btn}
              onClick={() => activeStatusHandler(false)}
            >
              Activate
            </button>
          ) : (
            <button
              className={styles.btn}
              onClick={() => activeStatusHandler(true)}
            >
              Deactivate
            </button>
          )}
        </div>
      </div>
    </>
  )
}
