import { IAudit } from '../../../../../../app/entities/Audit'
import { IOrderVehicle } from '../../../../../../app/entities/OrderVehicle'
import {
  CONDITION_ISSUES,
  QUESTIONS,
  SECTIONS,
  TORQUE_STICK_ISSUES,
  WHEEL_ISSUES,
} from '../../../../global/constants/audit-on-site-cancellation'
import {
  CANCEL_INSTALL_IMAGE_TYPES,
  ORDER_DETAILS_IMAGE_TYPES,
  PRE_INSTALL_IMAGE_TYPES,
} from '../../../../global/constants/image'
import { IAuditImage, IAuditSectionData } from '../../../../global/types/audit'

function createOnSiteCancellationAuditData(
  auditData: IAudit,
  orderVehicle: IOrderVehicle | null,
  technicianImages: IAuditImage[],
): { [key: string]: IAuditSectionData } {
  if (!orderVehicle) return {}
  const {
    cosReason1,
    cosReason2,
    cosReason3,
    cosTireLocation,
    tiresCombination,
  } = orderVehicle

  const { technician, operationDate } = auditData

  let technicianCallAnswer = `Unable to determine ${technician.name}'s work order time/date.`

  if (operationDate) {
    const installationTime = new Date(operationDate)
    try {
      technicianCallAnswer = `${
        technician.name
      }'s work order was for ${installationTime.toLocaleTimeString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      })}`
    } catch (error) {
      console.error('Error getting DVIR completion time:', error)
    }
  }

  const cancellationCondition = cosReason1 ?? 'Condition not provided'

  const cancellationReason = cosReason2
    ? [cosReason2, cosReason3].join(' ')
    : 'Reason not provided'

  const tireLocation = cosTireLocation ?? 'Location not provided'

  const isCourtesyTorqueCompleted = technicianImages.some((images) =>
    [
      ORDER_DETAILS_IMAGE_TYPES.first_lug_first_torque,
      ORDER_DETAILS_IMAGE_TYPES.torque_wrench_setting,
    ].includes(images.type),
  )

  const torqueValue =
    tiresCombination?.rearTire?.torque ||
    tiresCombination?.frontTire?.torque ||
    'N/A'

  const vehicleInformation = {
    brand: orderVehicle.customerVehicle?.brand?.name ?? '(Brand N/A)',
    model: orderVehicle.customerVehicle?.model ?? '(Model N/A)',
    year: orderVehicle.customerVehicle?.year ?? '(Year N/A)',
  }

  return {
    [SECTIONS.cancellation_condition]: {
      title: `OSC: ${cancellationCondition} / ${cancellationReason} / ${tireLocation}`,
      imageTypes: [
        PRE_INSTALL_IMAGE_TYPES.pre_vin,
        PRE_INSTALL_IMAGE_TYPES.pre_front_passenger,
        PRE_INSTALL_IMAGE_TYPES.pre_front_driver,
        PRE_INSTALL_IMAGE_TYPES.pre_rear_driver,
        PRE_INSTALL_IMAGE_TYPES.pre_rear_passenger,
        PRE_INSTALL_IMAGE_TYPES.pre_additional,
        ORDER_DETAILS_IMAGE_TYPES.left_work_area,
        ORDER_DETAILS_IMAGE_TYPES.right_work_area,
      ],
      question: QUESTIONS[SECTIONS.cancellation_condition],
      defects: {
        [WHEEL_ISSUES.noPhoto]: false,
        [WHEEL_ISSUES.loosenedLugs]: false,
        [WHEEL_ISSUES.removedWheel]: false,
      },
    },
    [SECTIONS.cancellation_torque_stick]: {
      title: `Torque Spec: ${torqueValue} ft-lbs`,
      imageTypes: [
        ORDER_DETAILS_IMAGE_TYPES.torque_wrench_setting,
        ORDER_DETAILS_IMAGE_TYPES.first_lug_first_torque,
      ],
      question: [
        QUESTIONS[SECTIONS.cancellation_torque_stick],
        `(Customer vehicle: ${vehicleInformation.year} ${vehicleInformation.brand} ${vehicleInformation.model})`,
      ].join(' '),
      answer: `Technician Response: ${
        isCourtesyTorqueCompleted ? 'Yes' : 'No'
      }`,
      defects: {
        [TORQUE_STICK_ISSUES.noCourtesyTorque]: !isCourtesyTorqueCompleted,
        [TORQUE_STICK_ISSUES.unconfirmedTorque]: false,
        [TORQUE_STICK_ISSUES.noPhoto]: false,
        [TORQUE_STICK_ISSUES.blurryBadPhoto]: false,
        [TORQUE_STICK_ISSUES.wrongPhoto]: false,
        [TORQUE_STICK_ISSUES.incorrectTorqueSetting]: false,
      },
    },
    [SECTIONS.cancellation_reason]: {
      title: cancellationReason,
      imageTypes: [CANCEL_INSTALL_IMAGE_TYPES.cancellation],
      question: QUESTIONS[SECTIONS.cancellation_reason],
      defects: {
        [CONDITION_ISSUES.noPhoto]: false,
        [CONDITION_ISSUES.blurryBadPhoto]: false,
        [CONDITION_ISSUES.wrongPhoto]: false,
      },
    },
  }
}

export { createOnSiteCancellationAuditData }
