import styles from './styles.module.css'
import { IAuditImage } from '../../../../global/types/audit'
import { Modal } from '@mui/material'

interface RenderFullScreenImageProps {
  image: IAuditImage
  fullscreenVisible: boolean
  setFullScreenVisible: Function
}

function FullScreenImage({
  image,
  fullscreenVisible,
  setFullScreenVisible,
}: RenderFullScreenImageProps) {
  return (
    <Modal open={fullscreenVisible}>
      <div className={styles.fullscreenContainer}>
        <img
          src={image.url}
          alt={image.type}
          onClick={() => setFullScreenVisible(false)}
        />
      </div>
    </Modal>
  )
}

export default FullScreenImage
