import { IAudit } from '../../../../../../app/entities/Audit'
import { ILocation } from '../../../../../../app/entities/Location'
import {
  QUESTIONS,
  SECTIONS,
  TITLES,
  DAILY_VALIDATOR_ISSUES,
  VAN_ISSUES,
} from '../../../../global/constants/audit-daily-dvir'
import { VIR_DAILY_PHOTOS } from '../../../../global/constants/virs'
import { IAuditSectionData } from '../../../../global/types/audit'
import { offsetDateByTimezoneOffset } from '../../../../global/utils/date/date-time-zone-utils'

export function createDailyDvirAuditData(auditData: IAudit): {
  [key: string]: IAuditSectionData
} {
  const dvirData = auditData.dvir
  let isCompletedLate = false
  let dvirTimeAnswer = 'Unable to determine time of completion.'

  if (dvirData) {
    const dvirEndTime = new Date(dvirData.endTime)
    try {
      const dvirEndTimeWithOffset = offsetDateByTimezoneOffset(
        dvirEndTime,
        auditData.technician.location || ({} as ILocation),
      )
      dvirTimeAnswer = `Technician finished DVIR at ${dvirEndTimeWithOffset.toLocaleTimeString(
        'en-US',
        { hour: 'numeric', minute: '2-digit' },
      )}`
      const isBefore10AM = dvirEndTimeWithOffset.getHours() < 10
      isCompletedLate = !isBefore10AM
    } catch (error) {
      console.error('Error getting DVIR completion time:', error)
    }
  }

  return {
    [SECTIONS.van_external_images]: {
      title: TITLES[SECTIONS.van_external_images],
      imageTypes: [
        VIR_DAILY_PHOTOS.DRIVER_SIDE_OF_VAN,
        VIR_DAILY_PHOTOS.PASSENGER_SIDE_OF_VAN,
        VIR_DAILY_PHOTOS.FRONT_OF_VAN,
        VIR_DAILY_PHOTOS.REAR_OF_VAN,
      ],
      question: QUESTIONS[SECTIONS.van_external_images],
      defects: {
        [VAN_ISSUES.incorrectVan]: false,
        [VAN_ISSUES.damageIdentified]: false,
      },
    },
    [SECTIONS.daily_validator]: {
      title: TITLES[SECTIONS.daily_validator],
      imageTypes: [VIR_DAILY_PHOTOS.VALIDATOR_RESULT],
      question: QUESTIONS[SECTIONS.daily_validator],
      defects: {
        [DAILY_VALIDATOR_ISSUES.unableToReadScreen]: false,
        [DAILY_VALIDATOR_ISSUES.wrongPhoto]: false,
        [DAILY_VALIDATOR_ISSUES.redLight]: false,
        [DAILY_VALIDATOR_ISSUES.broken]: false,
        [DAILY_VALIDATOR_ISSUES.outOfCalibration]: false,
        [DAILY_VALIDATOR_ISSUES.noPhoto]: false,
      },
    },
  }
}
