import { IAudit } from '../../../../../app/entities/Audit'

export function getAuditByObjectId(auditObjectId: string) {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/audit/byObjectId/${auditObjectId}`,
    method: 'GET',
  }
}
export function getExampleImages(type: 'dvir' | 'installation') {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/audit/example-images?type=${type}`,
    method: 'GET',
  }
}
export function getInstallationImages(orderVehicleObjectId: string) {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/images/installation/${orderVehicleObjectId}`,
    method: 'GET',
  }
}
export function getOrderVehicle(orderVehicleObjectId: string) {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/order_vehicles/${orderVehicleObjectId}`,
    method: 'GET',
  }
}
export function getDvirImages(virObjectId: string) {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/images/vir/${virObjectId}`,
    method: 'GET',
  }
}
export function putAudit(audit: IAudit) {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/audit/`,
    method: 'PUT',
    body: audit,
  }
}
