import { useContext, useState } from 'react'
import UsersDropDown from './UsersDropDown'
import { IUser } from '../../../../../app/entities/User'
import Button from '@mui/material/Button'
import { ToastNotificationContext } from '../../context/toast-context/ToastNotificationContext'
import { fetchData, IFetchDataProps } from '../../utils/fetch'

interface IAssignUserCellProps {
  assignedTo?: string
  usersList?: IUser[]
  objectId: string
  retrieveTableData: Function
  disabled: boolean
  assignUserTo: (
    objectId: string,
    assignedTo: string | null,
  ) => IFetchDataProps<any>
}

function AssignUserCell({
  assignedTo,
  usersList = [],
  objectId,
  retrieveTableData,
  disabled,
  assignUserTo,
}: IAssignUserCellProps) {
  const [showDropDown, setShowDropDown] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target
    const userValue = value === 'Unassign User' ? null : value
    const assignedOrUnassigned =
      value === 'Unassign User' ? 'unassigned' : 'assigned'

    try {
      setIsLoading(true)
      await fetchData(assignUserTo(objectId, userValue))
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `Successfully ${assignedOrUnassigned} user.`,
        severity: 'success',
      })
    } catch (error) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error assigning user.',
        severity: 'Error',
      })
    } finally {
      retrieveTableData()
      setIsLoading(false)
    }
  }

  if (showDropDown) {
    return (
      <UsersDropDown
        assignedUser={assignedTo}
        users={usersList}
        handleChange={handleChange}
        isLoading={isLoading}
      />
    )
  }

  return (
    <div>
      <Button
        onClick={() => setShowDropDown(true)}
        variant='outlined'
        color={assignedTo ? 'success' : 'warning'}
        size={'small'}
        style={{ textTransform: 'none' }}
        sx={{
          '&:hover': { backgroundColor: assignedTo ? '#6fbf73' : '#FFB000' },
        }}
        disabled={disabled}
      >
        {assignedTo ? assignedTo : 'Assign User'}
      </Button>
    </div>
  )
}

export default AssignUserCell
