import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { IUser } from '../../../../../app/entities/User'
import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'

interface IUserDropDownProps {
  users: IUser[]
  assignedUser?: string
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => Promise<void>
  isLoading?: boolean
}

const unassign: IUser = {
  objectId: '',
  created: 0,
  updated: 0,
  name: 'Unassign User',
  blUserLocale: '',
  email: '',
  phoneNumber: '',
  locations: [],
}
function UsersDropDown(props: IUserDropDownProps) {
  const { users, assignedUser, handleChange, isLoading } = props

  const getUsersList = (userList: IUser[]) =>
    userList.map((user: IUser, index: number) => (
      <MenuItem
        key={index}
        value={user.name}
      >
        {user.name}
      </MenuItem>
    ))

  //if there is a user assigned to the order, the option to unassign is added
  const usersList = assignedUser
    ? getUsersList([...[unassign], ...users])
    : getUsersList(users)

  if (isLoading) {
    return <CircularProgress />
  }
  return (
    <TextField
      value={assignedUser ?? ''}
      fullWidth
      onChange={handleChange}
      label='Select User'
      name='assignedTo'
      color='warning'
      size='small'
      select
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 300,
              width: 200,
            },
          },
        },
      }}
    >
      {usersList}
    </TextField>
  )
}
export default UsersDropDown
