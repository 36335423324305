import { useState, useEffect } from 'react'
import MainPageWrapper from '../../global/components/MainPageWrapper'
import TabChooser from './TabChooser'
import InstallationAuditsTable from '../components/tables/InstallationAuditsTable'
import LocationDropDown from '../../global/components/location-drop-down/LocationDropDown'
import { AuditContextProvider } from '../../global/context/audit-context/AuditContext'
import DvirAuditsTable from '../components/tables/DvirAuditsTable'
import CoachingAuditsTable from '../components/tables/CoachingAuditsTable'
import { AUDIT_KEYS } from '../../global/constants/audit'
export interface ILocationFilters {
  locationFilter: string
  setLocationFilter: Function
}

interface IAuditsMainProps {
  tabType: AUDIT_KEYS
}

function AuditsMain({ tabType }: IAuditsMainProps) {
  const [refetchIndex, setRefetchIndex] = useState(0)
  const [locationFilter, setLocationFilter] = useState('')

  let table = null

  const showLocationDropdown = [AUDIT_KEYS.coaching].includes(tabType)

  useEffect(() => {
    setLocationFilter('')
  }, [tabType])

  switch (tabType) {
    case AUDIT_KEYS.installation:
      table = (
        <InstallationAuditsTable
          setRefetchIndex={setRefetchIndex}
          refetchIndex={refetchIndex}
          locationFilters={{ locationFilter, setLocationFilter }}
        />
      )
      break
    case AUDIT_KEYS.dvir:
      table = (
        <DvirAuditsTable
          setRefetchIndex={setRefetchIndex}
          refetchIndex={refetchIndex}
          locationFilters={{ locationFilter, setLocationFilter }}
        />
      )
      break
    case AUDIT_KEYS.coaching:
      table = (
        <CoachingAuditsTable
          setRefetchIndex={setRefetchIndex}
          refetchIndex={refetchIndex}
          locationFilters={{ locationFilter, setLocationFilter }}
        />
      )
      break
  }

  return (
    <AuditContextProvider>
      <MainPageWrapper
        title='Audits'
        titleFields={
          showLocationDropdown ? (
            <div style={{ marginRight: 'auto', marginLeft: '1rem' }}>
              <LocationDropDown
                onLocationChange={(val: string) => {
                  setLocationFilter(val)
                }}
              />
            </div>
          ) : (
            <></>
          )
        }
      >
        <TabChooser />
        {table}
      </MainPageWrapper>
    </AuditContextProvider>
  )
}

export default AuditsMain
