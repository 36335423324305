import styles from './styles.module.css'
import { IAuditImage } from '../../../../global/types/audit'
import { useState } from 'react'
import FullScreenImage from './FullScreenImage'
import NavigationButton from '../../../../global/components/buttons/navigation-button/NavigationButton'
import { REQUIRED_INSTALLATION_IMAGE_BY_TYPE } from '../../../../global/constants/image'
interface ImagesDisplayProps {
  imageTypes: string[]
  technicianImages: IAuditImage[]
  exampleImages: IAuditImage[]
  currentImageIndex: number
  setCurrentImageIndex: Function
}

function isInstallationImageRequired(imageType: string): boolean {
  return REQUIRED_INSTALLATION_IMAGE_BY_TYPE.includes(imageType)
}

function ImagesDisplay({
  imageTypes,
  technicianImages,
  exampleImages,
  currentImageIndex,
  setCurrentImageIndex,
}: ImagesDisplayProps) {
  const [isFullScreenVisible, setFullScreenVisible] = useState(false)
  const imagesByType: {
    exampleImage: IAuditImage | undefined
    technicianImage: IAuditImage | undefined
  }[] = imageTypes
    .map((type) => {
      return {
        exampleImage: exampleImages.find((image) => image.type === type),
        technicianImage: technicianImages.find((image) => image.type === type),
      }
    })
    .filter(({ technicianImage }) => {
      //this filters out nonexisting nonrequired images
      const isTechnicianImageRequired = isInstallationImageRequired(
        technicianImage?.type ?? '',
      )
      const doesTechnicianImageExist = !!technicianImage?.url
      return isTechnicianImageRequired || doesTechnicianImageExist
    })

  const noImagesToRender = imagesByType.every((imageType) => {
    const noExampleImage =
      !imageType.exampleImage || !imageType.exampleImage.url
    const noTechnicianImage =
      !imageType.technicianImage || !imageType.technicianImage.url
    return noExampleImage && noTechnicianImage
  })
  if (noImagesToRender) {
    return <div className={styles.contentContainer}></div>
  }

  const oneImageToRender = imagesByType.length === 1

  if (oneImageToRender) {
    const exampleImageToRender = imagesByType[0].exampleImage
    const technicianImageToRender = imagesByType[0].technicianImage
    return (
      <div
        className={
          exampleImageToRender
            ? styles.contentContainer
            : styles.contentContainerWithoutExamples
        }
      >
        {!!exampleImageToRender && (
          <div className={styles.exampleContainer}>
            <div>
              <span style={{ fontWeight: 'bold' }}>EXAMPLE: </span>
              {exampleImageToRender.type}
            </div>
            <img
              src={exampleImageToRender.url}
              alt={exampleImageToRender.type}
            />
          </div>
        )}
        {!!technicianImageToRender ? (
          <>
            <FullScreenImage
              image={technicianImageToRender}
              fullscreenVisible={isFullScreenVisible}
              setFullScreenVisible={setFullScreenVisible}
            />
            <div
              className={
                exampleImageToRender
                  ? styles.imageContainer
                  : styles.imageContainerWithoutExamples
              }
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div>{technicianImageToRender.type}</div>
                <img
                  src={technicianImageToRender.url}
                  alt={technicianImageToRender.type}
                  onClick={() => setFullScreenVisible(true)}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.placeholderBox}>
            <div className={styles.placeholderCross}></div>
          </div>
        )}
      </div>
    )
  }

  function handleClick(input: 'prev' | 'next') {
    const shouldSubtractOne = input === 'prev'
    const addend = shouldSubtractOne ? -1 : 1
    const length = imagesByType.length
    const nextIndex = (currentImageIndex + addend + length) % length
    setCurrentImageIndex(nextIndex)
  }

  return (
    <div
      className={
        imagesByType[currentImageIndex].exampleImage
          ? styles.contentContainer
          : styles.contentContainerWithoutExamples
      }
    >
      {!!imagesByType[currentImageIndex].exampleImage && (
        <div className={styles.exampleContainer}>
          <div>
            <span style={{ fontWeight: 'bold' }}>EXAMPLE: </span>
            {imagesByType[currentImageIndex].exampleImage?.type}
          </div>
          <img
            src={imagesByType[currentImageIndex].exampleImage?.url}
            alt={imagesByType[currentImageIndex].exampleImage?.type}
          />
        </div>
      )}
      <div
        className={
          imagesByType[currentImageIndex].exampleImage
            ? styles.imageContainer
            : styles.imageContainerWithoutExamples
        }
      >
        <div className={styles.navigationButtons}>
          <NavigationButton
            direction='back'
            handleClick={() => handleClick('prev')}
          />
        </div>
        {!!imagesByType[currentImageIndex].technicianImage ? (
          <>
            <FullScreenImage
              image={
                imagesByType[currentImageIndex].technicianImage ??
                ({} as IAuditImage)
              }
              fullscreenVisible={isFullScreenVisible}
              setFullScreenVisible={setFullScreenVisible}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div>{imagesByType[currentImageIndex].technicianImage?.type}</div>
              <img
                src={imagesByType[currentImageIndex].technicianImage?.url}
                alt={imagesByType[currentImageIndex].technicianImage?.type}
                onClick={() => setFullScreenVisible(true)}
              />
            </div>
          </>
        ) : (
          <div className={styles.placeholderBox}>
            <div className={styles.placeholderCross}></div>
          </div>
        )}
        <div className={styles.navigationButtons}>
          <NavigationButton
            direction='forward'
            handleClick={() => handleClick('next')}
          />
        </div>
      </div>
      <div
        className={
          imagesByType[currentImageIndex].exampleImage
            ? styles.thumbnailContainer
            : styles.thumbnailContainerWithoutExamples
        }
      >
        {imagesByType.map((images, index) => (
          <img
            className={`${styles.thumbnail} + ${
              index === currentImageIndex ? styles.thumbnailActive : ''
            }`}
            key={index}
            src={images.technicianImage?.url}
            alt={images.technicianImage?.type}
            onClick={() => setCurrentImageIndex(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default ImagesDisplay
