export function getAuditAssignToUserList(permissionName: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/users/permission?permissionName=${permissionName}`,
  }
}

export function assignUserToAudit(
  auditObjectId: string,
  assignedToAudit: string | null,
) {
  const body = {
    assignedToAudit,
  }
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/audit/${auditObjectId}/assign-user`,
    body,
  }
}

export function assignUserToCoaching(
  auditObjectId: string,
  assignedToCoach: string | null,
) {
  const body = {
    assignedToCoach,
  }
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/audit/${auditObjectId}/assign-user`,
    body,
  }
}
