import { ILocation } from '../../../../../app/entities/Location'

function offsetDateByTimezoneOffset(date: Date, location: ILocation): Date {
  // Extract the sign, hours, and minutes from the timezone offset string
  const timezoneOffset = location.timezoneOffset
  if (!timezoneOffset) throw new Error('Timezone offset is required')
  const match = timezoneOffset.match(/([+-])(\d{1,2}):(\d{2})/)
  if (!match) throw new Error('Invalid timezone offset format')

  const [, sign, hours, minutes] = match

  // Convert the offset to total minutes
  const totalMinutes =
    (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1)

  // Create a new Date adjusted by the offset
  return new Date(date.getTime() + totalMinutes * 60 * 1000)
}

export { offsetDateByTimezoneOffset }
