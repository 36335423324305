import { ROUTE_OPTIMIZATION_HOUR_UTC } from '../constants/scheduling'

// This function is duplicated at 'app/utils/date/is-day-optimized.ts'.
// KEEP THEM IN SYNC!
function isDayOptimized(
  timestamp: number = Date.now(),
  requestedTime = Date.now(),
): boolean {
  /**
   * Determines if the given timestamp (in milliseconds) is "optimized".
   * A day becomes optimized at 19:00 UTC on the previous day.
   */

  const givenDate = new Date(timestamp)

  // Get the UTC date components
  const year = givenDate.getUTCFullYear()
  const month = givenDate.getUTCMonth()
  const day = givenDate.getUTCDate()

  // Create a Date object for the given day at 00:00 UTC
  const targetDate = new Date(Date.UTC(year, month, day))

  // Calculate the "optimized" threshold: Previous day at 19:00 UTC
  const optimizedStart = new Date(targetDate)
  optimizedStart.setUTCDate(targetDate.getUTCDate() - 1)
  optimizedStart.setUTCHours(ROUTE_OPTIMIZATION_HOUR_UTC, 0, 0, 0)
  console.log('optimizedStart', optimizedStart)

  // Get the current UTC time
  const now = new Date(requestedTime)
  console.log('now', now)

  const isOptimized = now >= optimizedStart

  // Return true if the current time is past the optimized threshold
  return isOptimized
}

export default isDayOptimized
