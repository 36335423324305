import { IAuditState } from '../../global/types/audit'

export const STATUS = {
  pass: 'pass',
  fail: 'fail',
  coached: 'coached',
}
function getStatusForAuditSection(
  auditState: IAuditState | null,
  section: string[],
) {
  if (!auditState) {
    return STATUS.pass
  }
  const areAllSectionsPassing = section.every((section) => {
    const auditSection = auditState[section]
    return !auditSection
  })
  if (areAllSectionsPassing) {
    return STATUS.pass
  }
  const areAllSectionsCoached = section.every((section) => {
    const auditSection = auditState[section]
    return auditSection && auditSection.coaching.coached
  })
  if (areAllSectionsCoached) {
    return STATUS.coached
  }
  return STATUS.fail
}

export default getStatusForAuditSection
