import { useEffect, useState } from 'react'
import GoogleAddressSearchBar from '../../../availability-by-address/components/auto-complete-search-bar/GoogleAddressSearchBar'
import { Address } from '../../../../../../app/types/scheduling'
import styles from './styles.module.css'
import { ISchedulerStateProps } from '../../../../schedule/components/schedule-modal/components/container-modal/initialData'
import { IFormDataProps } from '../../../../schedule/components/schedule-modal/components/customer-information/CustomerInformation'
import { fetchData } from '../../../../global/utils/fetch'
import { changeAddress } from '../../../../work-orders/components/work-order-detail/components/sub-modals/modal-content/change-address/api'
import { DATA_ERRORS } from '../../main/AppointmentBookingMain'
interface IUpdateAddressComponentProps {
  schedulerState: ISchedulerStateProps
  fetchOrderData: any
  setNextDisabled: Function
  setTrSalesError: Function
}
function generateAddressString(customerInformation: IFormDataProps) {
  const { address, state, city } = customerInformation

  return `${address}, ${city}, ${state}, USA`
}
export default function UpdateAddress({
  schedulerState,
  fetchOrderData,
  setNextDisabled,
  setTrSalesError,
}: IUpdateAddressComponentProps) {
  const { customerInformation } = schedulerState
  const isServicedInitialValue =
    customerInformation.address.length > 0 ? true : undefined
  const [addressInput, setAddressInput] = useState<string>(
    generateAddressString(customerInformation),
  )

  const [isZipCodeServiced, setIsZipCodeServiced] = useState<any>(
    isServicedInitialValue,
  )
  const [isUpdateAddressLoading, setIsUpdateAddressLoading] = useState(false)

  const disabledButtonLogic = !isZipCodeServiced || isUpdateAddressLoading

  useEffect(() => {
    setNextDisabled(disabledButtonLogic)
  }, [disabledButtonLogic])

  async function updateAddressHandler(address: Address) {
    try {
      setIsUpdateAddressLoading(true)

      await fetchData(
        changeAddress({
          address: address.address,
          aptOrSuite: '',
          city: address.city,
          orderObjectId: schedulerState.orderId,
          state: address.state,
          zipCode: address.zipCode,
        }),
      )

      await fetchOrderData()
    } catch (err) {
      console.error(err)
      setTrSalesError(DATA_ERRORS.UPDATE_ADDRESS_ERROR)
    } finally {
      setIsUpdateAddressLoading(false)
    }
  }

  return (
    <div className={styles.addressContainer}>
      <GoogleAddressSearchBar
        addressInput={addressInput}
        setAddressInput={setAddressInput}
        setIsZipCodeServiced={setIsZipCodeServiced}
        setFormattedAddress={updateAddressHandler}
      />
    </div>
  )
}
