import { IAuditDefect, IAuditPrompt } from '../types/audit'

const initialCoachingPrompt: IAuditPrompt = {
  Notes: {
    value: '',
    textProps: {
      type: 'text',
      multiline: true,
      rows: 6,
    },
  },
}

const COACHING_DEFECTS = {
  coachingIsNotNeeded: 'Coaching not needed',
}

const initialCoachingDefects: IAuditDefect = {
  [COACHING_DEFECTS.coachingIsNotNeeded]: false,
}

export { initialCoachingPrompt, initialCoachingDefects, COACHING_DEFECTS }
