import { useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import {
  COACHING_DEFECTS,
  initialCoachingDefects,
  initialCoachingPrompt,
} from '../../../global/constants/audit-coaching'
import { AuditContext } from '../../../global/context/audit-context/AuditContext'
import {
  IAuditDefect,
  IAuditPrompt,
  IAuditState,
} from '../../../global/types/audit'
import { composeResponseString } from '../../utils/composeResponseString'
import DefectsDisplay from '../common/defects-display/DefectsDisplay'
import ImagesDisplay from '../common/images-display/ImagesDisplay'
import TextBoxDisplay from '../common/text-box-display/TextBoxDisplay'
import styles from './styles.module.css'

interface IModalContentProps {
  section: string
  handleNextSection: (newAuditState: IAuditState) => void
  handleClose: (newAuditState: IAuditState) => Promise<void>
}

export default function ModalContent({
  section,
  handleNextSection,
  handleClose,
}: IModalContentProps) {
  const {
    auditSectionData,
    technicianImages,
    exampleImages,
    auditState,
    technician,
    orderVehicle,
  } = useContext(AuditContext)
  if (!auditState) return <></>

  const coachingSectionData = auditState[section]
  const correspondingAuditSectionData = auditSectionData[section]
  const { question, response, coaching } = coachingSectionData
  const { title, imageTypes } = correspondingAuditSectionData
  const workOrderNumber = orderVehicle?.orderVehicleId?.split('-')[0] //sneaky way to grab the work order number without having to pull up the order
  const workOrderString = !!workOrderNumber ? ` - WO: ${workOrderNumber}` : ''

  const [defectsState, setDefectsState] = useState<IAuditDefect>(
    initialCoachingDefects,
  )
  const [promptState, setPromptState] = useState<IAuditPrompt>(
    initialCoachingPrompt,
  )
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const sectionsNeedingCoaching = Object.entries(auditState)
    .filter(([_, value]) => value.coaching.needsCoaching)
    .map(([key, _]) => key)
  const sectionIndex = sectionsNeedingCoaching.indexOf(section) + 1
  const totalSections = sectionsNeedingCoaching.length
  const progressString = `${sectionIndex} of ${totalSections}`

  const responseString = composeResponseString(response)

  function handleNextClick() {
    const selectedDefects = Object.fromEntries(
      Object.entries(defectsState).filter(([_, value]) => value),
    )
    const isCoachingNotNeeded =
      selectedDefects[COACHING_DEFECTS.coachingIsNotNeeded]

    const promptValue = Object.values(promptState).map((values) => values.value)

    if (auditState) {
      let newAuditState: IAuditState = { ...auditState }
      if (isCoachingNotNeeded) {
        newAuditState[section] = {
          ...auditState[section],
          coaching: {
            coached: true,
            needsCoaching: false,
            notes: promptValue.join(' '),
          },
        }
      } else {
        newAuditState[section] = {
          ...auditState[section],
          coaching: {
            coached: true,
            needsCoaching: true,
            notes: promptValue.join(' '),
          },
        }
      }
      handleNextSection(newAuditState)
    }
    setDefectsState(initialCoachingDefects)
    setPromptState(initialCoachingPrompt)
    setCurrentImageIndex(0)
  }

  useEffect(() => {
    const selectedDefects = Object.fromEntries(
      Object.entries(defectsState).filter(([_, value]) => value),
    )
    const isCoachingNotNeeded =
      selectedDefects[COACHING_DEFECTS.coachingIsNotNeeded]

    const promptTyped = Object.values(promptState).every(
      (prompt) => prompt.value.length > 0,
    )
    if (isCoachingNotNeeded || promptTyped) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [defectsState, promptState])

  useEffect(() => {
    setPromptState({
      Notes: {
        value: coaching.notes,
        textProps: {
          type: 'text',
          multiline: true,
          rows: 6,
        },
      },
    })
  }, [coaching])

  return (
    <>
      <div className={styles.pageTitle}>Coaching - {title}</div>
      <div className={styles.techContainer}>
        {technician}
        {workOrderString}
      </div>
      <div className={styles.questionContainer}>
        {question}
        <span style={{ fontWeight: 'bold' }}>{responseString}</span>
      </div>
      <div className={styles.imagesContainer}>
        <ImagesDisplay
          imageTypes={imageTypes}
          technicianImages={technicianImages}
          exampleImages={exampleImages}
          currentImageIndex={currentImageIndex}
          setCurrentImageIndex={setCurrentImageIndex}
        />
      </div>
      <div className={styles.inputContainer}>
        <TextBoxDisplay
          promptState={promptState}
          setPromptState={setPromptState}
        />
        <DefectsDisplay
          defectsState={defectsState}
          setDefectsState={setDefectsState}
        />
      </div>
      <div className={styles.buttonContainer}>
        <PrimaryButton
          onClick={() => handleNextClick()}
          buttonName={'Next'}
          disabled={isDisabled}
        />
      </div>
      <div className={styles.footerContainer}>
        <div>
          <button
            className={styles.footerButton}
            onClick={() => handleClose(auditState)}
          >
            Save and Exit
          </button>
          {progressString}
        </div>
      </div>
    </>
  )
}
