import { getISOWeekNumber } from '../../global/utils/date/get-iso-week-number'

function getMondayOfISOWeek(isoWeekNumber: number): Date {
  const now = new Date()
  const currentISOWeek = getISOWeekNumber(now)
  let year = now.getFullYear()
  if (isoWeekNumber > currentISOWeek) {
    year -= 1
  }

  const firstThursday = new Date(year, 0, 4)
  const firstMonday = new Date(firstThursday)
  firstMonday.setDate(
    firstThursday.getDate() - ((firstThursday.getDay() + 6) % 7),
  )

  const mondayOfWeek = new Date(firstMonday)
  mondayOfWeek.setDate(mondayOfWeek.getDate() + (isoWeekNumber - 1) * 7)

  return mondayOfWeek
}

export { getMondayOfISOWeek }
