import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import { ITechnician } from '../../../../../../app/entities/Technician'
import { getISOWeekNumber } from '../../../../global/utils/date/get-iso-week-number'
import { STATUS } from '../../../../global/types/audit'
import { CompletedInstallationTable } from './components/CompletedInstallationDashboardTable'
import { OnSiteCancellationTable } from './components/OnSiteCancellationDashboardTable'

interface IInstallationAuditModalProps {
  isOpen: boolean
  setIsOpen: Function
  technician: ITechnician | null
}

export default function InstallationAuditModal(
  props: IInstallationAuditModalProps,
) {
  const { isOpen, setIsOpen, technician } = props
  if (!technician) return <> </>
  const weekNumber = getISOWeekNumber(new Date())
  const completedInstallations = technician.audit?.filter(
    (audit) => audit.status === STATUS.COMPLETED_INSTALLATION,
  )
  const onSiteCancellations = technician.audit?.filter(
    (audit) => audit.status === STATUS.ON_SITE_CANCELLATION,
  )

  async function handleClosed(): Promise<void> {
    setIsOpen(false)
  }
  const isOverallScore = technician?.location?.name === 'Overall Score'
  const displayName = isOverallScore ? 'Overall Audits' : technician.name

  return (
    <Modal
      open={isOpen}
      onClose={() => handleClosed()}
    >
      <Box className={styles.auditModal}>
        <div className={styles.modalTitle}>
          <span style={{ fontWeight: 700 }}>{displayName}:</span> Week{' '}
          {weekNumber}
        </div>
        <div className={styles.tableContaier}>
          <div>
            {completedInstallations && completedInstallations.length > 0 && (
              <CompletedInstallationTable audits={completedInstallations} />
            )}
          </div>
          <div style={{ marginTop: '20px' }}>
            {onSiteCancellations && onSiteCancellations.length > 0 && (
              <OnSiteCancellationTable audits={onSiteCancellations} />
            )}
          </div>
        </div>
      </Box>
    </Modal>
  )
}
