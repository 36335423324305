import Button from '@mui/material/Button'
import { useContext } from 'react'
import { IAudit } from '../../../../../../app/entities/Audit'
import { AUDIT_PROGRESS } from '../../../../global/constants/audit'
import { AuditContext } from '../../../../global/context/audit-context/AuditContext'

interface IStartCellProps {
  audit: IAudit
  setModalOpen: Function
  disabled: boolean
  onClickAction?: () => Promise<void>
}

function StartCell({
  audit,
  setModalOpen,
  disabled,
  onClickAction,
}: IStartCellProps) {
  const { progress, state } = audit
  const needsCoaching = progress === AUDIT_PROGRESS.needsCoaching
  let isInProgress = Boolean(progress)

  if (needsCoaching && state) {
    isInProgress = Object.values(state).some(
      (section) => section.coaching.needsCoaching && section.coaching.coached,
    )
  }

  const { queryAuditData, setIsAuditDataLoading } = useContext(AuditContext)

  async function onClickHandler() {
    try {
      setIsAuditDataLoading(true)
      setModalOpen(true)
      onClickAction && (await onClickAction())
      await queryAuditData(audit.objectId)
      setIsAuditDataLoading(false)
    } catch (error) {
      console.error('Error fetching audit data:', error)
    } finally {
      setIsAuditDataLoading(false)
    }
  }

  return (
    <div>
      <Button
        onClick={() => onClickHandler()}
        variant='outlined'
        size={'small'}
        color={'inherit'}
        style={{ textTransform: 'none' }}
        sx={{
          color: isInProgress ? '#D7ABFF' : '#D70000',
          '&:hover': { backgroundColor: isInProgress ? '#F5EAFF' : '#d69090' },
        }}
        disabled={disabled}
      >
        {isInProgress ? 'Resume' : 'Start'}
      </Button>
    </div>
  )
}

export default StartCell
