import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Column } from 'react-table'
import Table from '../../../global/components/table/Table'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import { IOrder } from '../../../../../app/entities/Order'
import { ILocationFilters } from '../main/QueuesMain'
import WorkOrderDetailModal from '../../../work-orders/components/work-order-detail/components/main/WorkOrderDetailModal'
import { WoDetailContext } from '../../../work-orders/components/context/context'
import removeDoubleCommas from '../../../global/utils/removeDoubleCommas'
import useFetch from '../../../global/hooks/useFetchHook'
import { assignUserToOrder, getRescheduleQueueAssignToUsersList } from '../api'
import { IUser } from '../../../../../app/entities/User'
import AssignUserCell from '../../../global/components/assign-user/AssignUserCell'
import hasPermission from '../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../global/constants/permissions'
import { UserContext } from '../../../base/components/base-container/BaseContainer'

interface IWorkOrdersTableProps {
  locationFilters: ILocationFilters
}

function RescheduleQueueTable(props: IWorkOrdersTableProps) {
  const { user } = useContext(UserContext)
  const { id } = useParams()

  const { data: usersList } = useFetch<IUser[]>(
    getRescheduleQueueAssignToUsersList(),
  )
  const { locationFilter } = props.locationFilters
  const { queryOrderData, clearData } = useContext(WoDetailContext)
  const navigate = useNavigate()
  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false)
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    count: 0,
    pageSize: 100,
    offSet: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})

  async function handleDetailsOpen(orderObjectId: string) {
    setDetailsModalOpen(true)

    const url = `/queues/reschedule/${orderObjectId}`
    navigate(url)
    await queryOrderData(orderObjectId)
  }

  async function handleDetailsClosed() {
    setDetailsModalOpen(false)
    navigate(`/queues`)

    clearData()
    retrieveTableData()
  }

  function onRecordClickHandler(row: any) {
    const {
      original: { objectId: orderObjectId },
    } = row
    handleDetailsOpen(orderObjectId)
  }

  const onColumnFilterUpdate = (filtersUpdate: object) => {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  useEffect(() => {
    if (id && !detailsModalOpen) {
      handleDetailsOpen(id)
    }
  }, [id])

  const endpoint = `${process.env.REACT_APP_ORIGIN}/orders/pending-rescheduling?`
  function retrieveTableData() {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      sortOrder: 'DESC',
      selectedLocationId: locationFilter,
      columnFilters,
      dataTransformer: (data: any) => {
        data.items.forEach((item: any) => {
          if (item.installationPoint) {
            item.installationPoint.fullAddress = removeDoubleCommas(
              item.installationPoint?.fullAddress,
            )
          }
        })

        return data.items
      },
    })
  }

  useEffect(retrieveTableData, [
    locationFilter,
    tableState.pageSize,
    tableState.offSet,
  ])

  const columns: Column<IOrder | any>[] = [
    {
      Header: 'Assigned To',
      accessor: 'assignedTo',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ assignedTo: val }),
        onEnter: retrieveTableData,
      }),
      Cell: ({ row: { original } }) => {
        const { assignedTo, objectId } = original as any
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <AssignUserCell
              assignedTo={assignedTo && assignedTo}
              usersList={usersList ?? []}
              objectId={objectId}
              retrieveTableData={retrieveTableData}
              disabled={
                !hasPermission(user).allow(
                  PERMISSIONS.QUEUES.CATEGORY,
                  PERMISSIONS.QUEUES.ENTRIES.RESCHEDULE_ENTRIES_ASSIGNER.NAME,
                )
              }
              assignUserTo={assignUserToOrder}
            />
          </div>
        )
      },
    },
    {
      Header: 'Order ID',
      accessor: 'workOrderId',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ workOrderId: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'TR Order ID',
      accessor: 'tireRackOrders.0.orderNumber',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({
            tireRackOrders: {
              orderNumber: val,
            },
          }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Location',
      accessor: 'location.name',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ location: { name: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Technician Name',
      accessor: 'routeArea.technician.name',
      Cell: ({ row: { original } }) => {
        const { routeArea, routeInstructions } = original as any

        if (routeInstructions.length) {
          const instruction = routeInstructions[0]
          const technicianName = instruction.route?.technician?.name
          if (technicianName) return <div>{technicianName}</div>
        }
        return <div>{routeArea?.technician?.name}</div>
      },
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({
            routeArea: {
              technician: {
                name: val,
              },
            },
          }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Service Appt',
      accessor: 'timeSlot.fromDateGenerated',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({
            timeSlot: {
              fromDateGenerated: val,
            },
          }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Time Slot',
      accessor: 'timeSlot.type',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({
            timeSlot: {
              type: val,
            },
          }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'First Name',
      accessor: 'customer.firstName',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { firstName: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Last Name',
      accessor: 'customer.lastName',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { lastName: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Customer Email',
      accessor: 'customer.email',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { email: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Customer Phone',
      accessor: 'customer.trimmedPhoneNumber',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { trimmedPhoneNumber: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Installation Address',
      accessor: 'installationPoint.fullAddress',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ installationPoint: { fullAddress: val } }),
        onEnter: retrieveTableData,
      }),
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <WorkOrderDetailModal
        handleClosed={handleDetailsClosed}
        isOpen={detailsModalOpen}
      />
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isLoading={tableState.isLoading}
        onRowClick={onRecordClickHandler}
        isPaginated
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default RescheduleQueueTable
