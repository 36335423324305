enum STATUS {
  ON_SITE_CANCELLATION = 'on-site-cancellation',
  COMPLETED_INSTALLATION = 'completed-installation',
  DAILY_DVIR = 'daily-dvir',
  WEEKLY_DVIR = 'weekly-dvir',
}

export interface IAuditSectionData {
  title: string
  imageTypes: string[]
  question: string
  answer?: string
  defects?: IAuditDefect
  options?: IAuditOption
  prompt?: IAuditPrompt
}

export interface ICoachingSectionData {
  title: string
  imageTypes: string[]
  question: string
  response: { [key: string]: boolean | string }
  defects: IAuditDefect
  prompt: IAuditPrompt
}

export interface IAuditDefect {
  [key: string]: boolean
}

export interface IAuditOption {
  [key: string]: { selection: boolean; answerKey: boolean }
}

export interface IAuditPrompt {
  [key: string]: {
    value: string
    textProps: {
      type: 'text' | 'number'
      multiline: boolean
      rows: number
    }
  }
}

export interface IAuditImage {
  url: string
  type: string
}
export interface IAuditState {
  [sectionKey: string]: {
    question: string
    response: {
      [key: string]: boolean | string
    }
    coaching: {
      coached: boolean
      needsCoaching: boolean
      notes: string
    }
  }
}

export { STATUS }
