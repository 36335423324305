function getISOWeekNumber(dateInput: Date): number {
  const date = new Date(dateInput)
  date.setUTCHours(0, 0, 0, 0)

  const thursday = new Date(date)
  thursday.setUTCDate(date.getUTCDate() + (4 - (date.getUTCDay() || 7)))

  const firstJan = new Date(thursday.getUTCFullYear(), 0, 1)
  firstJan.setUTCHours(0, 0, 0, 0)

  const weekNumber = Math.ceil(
    ((thursday.getTime() - firstJan.getTime()) / 86400000 + 1) / 7,
  )
  return weekNumber
}

export { getISOWeekNumber }
