import { IAuditState } from '../../global/types/audit'

function checkIsCoached(newAuditState: IAuditState | null): boolean {
  if (!newAuditState) return true
  const sections = Object.keys(newAuditState)
  const isCoached = sections.every((section) => {
    const sectionIsCoached = newAuditState[section].coaching.coached
    const sectionDoesNotNeedCoaching =
      !newAuditState[section].coaching.needsCoaching
    return sectionIsCoached || sectionDoesNotNeedCoaching
  })
  return isCoached
}

export { checkIsCoached }
