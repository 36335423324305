import { useContext } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import CircularProgress from '@mui/material/CircularProgress'
import ModalContent from './ModalContent'
import { AuditContext } from '../../../global/context/audit-context/AuditContext'
import { ToastNotificationContext } from '../../../global/context/toast-context/ToastNotificationContext'
import { IAuditState } from '../../../global/types/audit'
import { AUDIT_PROGRESS } from '../../../global/constants/audit'
import { getNextSection, getPreviousSection } from '../../utils/getAuditSection'

interface IAuditModalProps {
  isOpen: boolean
  setIsOpen: Function
  refetchIndex: number
  setRefetchIndex: Function
}

export default function AuditModal(props: IAuditModalProps) {
  const { isOpen, setIsOpen, setRefetchIndex, refetchIndex } = props
  const {
    isAuditDataLoading,
    progress,
    setProgress,
    saveAuditData,
    auditStatus,
    auditState,
    setAuditState,
  } = useContext(AuditContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  async function handleClosed(
    section: string,
    newAuditState: IAuditState | null,
  ): Promise<void> {
    setIsOpen(false)
    const isFinished = [
      AUDIT_PROGRESS.completed,
      AUDIT_PROGRESS.needsCoaching,
    ].includes(section)
    const savedOrFinished = isFinished ? 'finished' : 'saved'
    try {
      await saveAuditData(section, newAuditState)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `Successfully ${savedOrFinished} audit.`,
        severity: 'success',
      })
    } catch (error) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error saving the audit.',
        severity: 'error',
      })
    } finally {
      setRefetchIndex(refetchIndex + 1)
    }
  }

  function handleNextSection(newAuditState: IAuditState | null): void {
    let nextSection = getNextSection(progress, newAuditState, auditStatus)
    setProgress(nextSection)
    if (
      [AUDIT_PROGRESS.completed, AUDIT_PROGRESS.needsCoaching].includes(
        nextSection,
      )
    ) {
      handleClosed(nextSection, newAuditState)
    }
    setAuditState(newAuditState)
  }

  function handlePreviousSection(): void {
    let previousSection = getPreviousSection(progress, auditStatus)
    setProgress(previousSection)
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => handleClosed(progress, auditState)}
      >
        <Box className={styles.auditModal}>
          {isAuditDataLoading && (
            <div className={styles.progressContainer}>
              <CircularProgress />
            </div>
          )}
          {!isAuditDataLoading && (
            <ModalContent
              section={progress}
              handleNextSection={handleNextSection}
              handlePreviousSection={handlePreviousSection}
              handleClose={handleClosed}
            />
          )}
        </Box>
      </Modal>
    </div>
  )
}
