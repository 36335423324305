import styles from './styles.module.css'

interface PassFailButtonProps {
  pass: boolean
  onClick: () => void
  disabled?: boolean
  passLabel?: string
}
export default function PassFailButton({
  pass,
  onClick,
  disabled,
  passLabel,
}: PassFailButtonProps) {
  const buttonName = pass ? passLabel?.toUpperCase() ?? 'PASS' : 'COACH'
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={pass ? styles.passButton : styles.failButton}
    >
      <span className={styles.buttonText}>{buttonName}</span>
    </button>
  )
}
