import { useContext, useEffect, useState } from 'react'
import { IVan } from '../../../../../app/entities/Van'
import {
  BARTEC_SERIAL_NUMBER_KEY,
  GAS_CARD_NUMBER_KEY,
  IN_SERVICE_DATE_CHEETAH_KEY,
  REAR_LICENSE_PLATE_KEY,
  SERIAL_NUMBER_CHEETAH_KEY,
} from '../../../global/constants/audit-weekly-dvir'
import { AuditContext } from '../../../global/context/audit-context/AuditContext'
import {
  IAuditDefect,
  IAuditOption,
  IAuditPrompt,
  IAuditState,
} from '../../../global/types/audit'
import { fetchData } from '../../../global/utils/fetch'
import PassFailButton from '../common/pass-fail-button/PassFailButton'
import DefectsDisplay from '../common/defects-display/DefectsDisplay'
import ImagesDisplay from '../common/images-display/ImagesDisplay'
import RadioButtonsDisplay from '../common/radio-buttons-display/RadioButtonsDisplay'
import TextBoxDisplay from '../common/text-box-display/TextBoxDisplay'
import { updateVan } from './api'
import styles from './styles.module.css'
import { initialAuditNote } from '../../../global/constants/audit'
import { ToastNotificationContext } from '../../../global/context/toast-context/ToastNotificationContext'

async function updateVanData(
  promptKey: string,
  value: string,
  van: IVan | undefined,
) {
  const shouldUpdateVanData = [
    REAR_LICENSE_PLATE_KEY,
    BARTEC_SERIAL_NUMBER_KEY,
    SERIAL_NUMBER_CHEETAH_KEY,
    IN_SERVICE_DATE_CHEETAH_KEY,
    GAS_CARD_NUMBER_KEY,
  ].includes(promptKey)
  if (!shouldUpdateVanData || !van) return

  switch (promptKey) {
    case REAR_LICENSE_PLATE_KEY:
      van.licensePlate = value
      break
    case BARTEC_SERIAL_NUMBER_KEY:
      van.bartechSerialNumber = value
      break
    case SERIAL_NUMBER_CHEETAH_KEY:
      van.cheetahSerialNumber = value
      break
    case IN_SERVICE_DATE_CHEETAH_KEY:
      van.cheetahInServiceDate = value
      break
    case GAS_CARD_NUMBER_KEY:
      van.gasCardLastFiveDigits = value
      break
  }
  await fetchData(updateVan(van))
}

interface IModalContentProps {
  section: string
  handleNextSection: (newAuditState: IAuditState | null) => void
  handlePreviousSection: () => void
  handleClose: (
    newProgress: string,
    newAuditState: IAuditState | null,
  ) => Promise<void>
}

export default function ModalContent({
  section,
  handleNextSection,
  handlePreviousSection,
  handleClose,
}: IModalContentProps) {
  const {
    auditSectionData,
    technicianImages,
    exampleImages,
    auditState,
    sections,
    auditData,
  } = useContext(AuditContext)

  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const currentInstallationSection = auditSectionData[section]

  const { title, imageTypes, question, answer, defects, options, prompt } =
    currentInstallationSection

  const [defectsState, setDefectsState] = useState<IAuditDefect>({})
  const [optionsState, setOptionsState] = useState<IAuditOption>({})
  const [promptState, setPromptState] = useState<IAuditPrompt>({})
  const [optionalNoteState, setOptionalNoteState] =
    useState<IAuditPrompt>(initialAuditNote)
  const [isPassing, setIsPassing] = useState<boolean>(true)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const sectionIndex = Object.values(sections).indexOf(section) + 1
  const totalSections = Object.keys(sections).length - 1
  const progressString = `${sectionIndex} of ${totalSections}`

  function handleBackClick() {
    setToastStatus({
      ...toastStatus,
      isOpen: true,
      message: `Your forward progress has been lost.`,
      severity: 'error',
    })
    handlePreviousSection()
  }

  function handlePassFailClick() {
    const selectedDefects = Object.fromEntries(
      Object.entries(defectsState).filter(([_, value]) => value),
    )

    const selectedOption = Object.fromEntries(
      Object.entries(optionsState)
        .filter(([_, value]) => {
          const isSelectedOption = value.selection
          const isFailingSelection = value.selection !== value.answerKey
          return isSelectedOption && isFailingSelection
        })
        .map(([key, value]) => [key, value.selection]),
    )

    const requiredPromptValues = Object.fromEntries(
      Object.entries(promptState).map(([key, prompt]) => {
        updateVanData(key, prompt.value, auditData?.dvir?.van)
        return [key, prompt.value]
      }),
    )
    const optionalPromptValues = Object.entries(optionalNoteState)
      .map(([_, prompt]) => prompt.value)
      .join(' ')

    const newResponses = {
      ...selectedDefects,
      ...selectedOption,
      ...requiredPromptValues,
    }
    const shouldUpdateState = Object.keys(newResponses).length > 0
    const needsCoaching =
      Object.keys({ ...selectedDefects, ...selectedOption }).length > 0
    let newAuditState = auditState
    if (shouldUpdateState) {
      newAuditState = {
        ...auditState,
        [section]: {
          question,
          response: newResponses,
          coaching: {
            coached: false,
            needsCoaching,
            notes: optionalPromptValues
              ? '(From auditor: ' + optionalPromptValues + ')'
              : '',
          },
        },
      }
    }
    handleNextSection(newAuditState)
    setOptionalNoteState(initialAuditNote)
    setCurrentImageIndex(0)
  }

  useEffect(() => {
    setDefectsState(defects || {})
    setOptionsState(options || {})
    setPromptState(prompt || {})
  }, [defects, options, prompt])

  useEffect(() => {
    const defectsSelected = Object.values(defectsState).some((defect) => defect)
    const optionsSelected = Object.values(optionsState).some(
      (option) => option.selection,
    )
    const isPassingSelection = Object.values(optionsState).every(
      (option) => option.selection === option.answerKey,
    )
    const requiredPromptTyped = Object.values(promptState).every(
      (prompt) => prompt.value.length > 0,
    )
    const optionalPromptTyped = Object.values(optionalNoteState).some(
      (prompt) => prompt.value.length > 0,
    )
    if (defects) {
      if (prompt) {
        setIsDisabled(!defectsSelected && !requiredPromptTyped)
      } else {
        setIsDisabled(false)
      }
      if (optionalPromptTyped) {
        setIsDisabled(!defectsSelected)
      }
      setIsPassing(!defectsSelected)
    } else if (options) {
      setIsDisabled(!optionsSelected)
      if (optionsSelected) setIsPassing(isPassingSelection)
    }
  }, [optionsState, defectsState, promptState, optionalNoteState])

  return (
    <>
      <div className={styles.pageTitle}>{title}</div>
      <div className={styles.questionContainer}>
        {question}
        <span style={{ fontWeight: 'bold' }}>{answer}</span>
      </div>
      <div className={styles.imagesContainer}>
        <ImagesDisplay
          imageTypes={imageTypes}
          technicianImages={technicianImages}
          exampleImages={exampleImages}
          currentImageIndex={currentImageIndex}
          setCurrentImageIndex={setCurrentImageIndex}
        />
      </div>
      <div className={styles.inputContainer}>
        {prompt && (
          <TextBoxDisplay
            promptState={promptState}
            setPromptState={setPromptState}
          />
        )}
        {defects && (
          <DefectsDisplay
            defectsState={defectsState}
            setDefectsState={setDefectsState}
          />
        )}
        {options && (
          <RadioButtonsDisplay
            optionsState={optionsState}
            setOptionsState={setOptionsState}
          />
        )}
        <TextBoxDisplay
          promptState={optionalNoteState}
          setPromptState={setOptionalNoteState}
        />
      </div>
      <div className={styles.buttonContainer}>
        <PassFailButton
          pass={isPassing}
          onClick={handlePassFailClick}
          disabled={isDisabled}
          passLabel={prompt ? 'next' : 'pass'}
        />
      </div>
      <div className={styles.footerContainer}>
        <div>
          <button
            className={styles.footerButton}
            onClick={() => handleBackClick()}
          >
            Back
          </button>
        </div>
        <div>
          <button
            className={styles.footerButton}
            onClick={() => handleClose(section, auditState)}
          >
            Save and Exit
          </button>
          {progressString}
        </div>
      </div>
    </>
  )
}
