import styles from './styles.module.css'
import PrimaryCheckbox from '../../../../global/components/checkbox/Checkbox'
import { IAuditDefect } from '../../../../global/types/audit'

interface DefectsDisplayProps {
  defectsState: IAuditDefect
  setDefectsState: Function
}

function DefectsDisplay({
  defectsState,
  setDefectsState,
}: DefectsDisplayProps) {
  function handleClick(defectKey: string) {
    const newDefects = { ...defectsState }
    newDefects[defectKey] = !newDefects[defectKey]
    setDefectsState(newDefects)
  }

  return (
    <div className={styles.defectsContainer}>
      {Object.entries(defectsState).map(([defectKey, defectValue]) => (
        <PrimaryCheckbox
          key={defectKey}
          checked={defectValue}
          label={defectKey}
          onChange={() => handleClick(defectKey)}
        />
      ))}
    </div>
  )
}

export default DefectsDisplay
