import { useState } from 'react'
import { Column } from 'react-table'
import { IAudit } from '../../../../../../../app/entities/Audit'
import TableTitle from '../../../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../../../global/components/table/helpers/table-topper/TableTopper'
import Table from '../../../../../global/components/table/Table'
import { SECTIONS } from '../../../../../global/constants/audit-weekly-dvir'
import { formatDate } from '../../../../../global/utils/date/mm-dd-yyyy'
import getStatusForAuditSection from '../../../../utils/get-statuses-for-audit-section'
import { StatusDot } from '../../../common/StatusDot'

function WeeklyDvirTable({ audits }: { audits: IAudit[] }) {
  const [isOpen, setIsOpen] = useState(true)

  const weeklyDvircolumns: Column<IAudit | any>[] = [
    {
      Header: 'DVIR Date',
      accessor: 'operationDate',
      Cell: ({ row: { original } }) => {
        const date = new Date(original.operationDate)
        const formattedDate = formatDate(date)
        return <>{formattedDate}</>
      },
    },
    {
      Header: 'Van Name',
      accessor: 'dvir.van.name',
    },
    {
      Header: 'Damage',
      accessor: 'damage',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failure = getStatusForAuditSection(state, [
          SECTIONS.driver_van_exterior,
          SECTIONS.front_of_van,
          SECTIONS.rear_of_van,
          SECTIONS.passenger_van_exterior,
          SECTIONS.passenger_side_interior,
          SECTIONS.rear_interior_cargo_area,
        ])
        return StatusDot(failure)
      },
    },
    {
      Header: 'Cleanliness',
      accessor: 'cleanliness',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failure = getStatusForAuditSection(state, [
          SECTIONS.passenger_side_interior,
          SECTIONS.van_interior_work_area,
          SECTIONS.driver_side_interior,
        ])
        return StatusDot(failure)
      },
    },
    {
      Header: 'Equipment',
      accessor: 'equipment',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failure = getStatusForAuditSection(state, [
          SECTIONS.torque_wrench_window,
          SECTIONS.balancing_cup_condition,
          SECTIONS.fire_extinguisher,
          SECTIONS.first_aid_kit,
          SECTIONS.ansi_osha_warning_triangles,
        ])
        return StatusDot(failure)
      },
    },
    {
      Header: 'Data Capture',
      accessor: 'dataCapture',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failure = getStatusForAuditSection(state, [
          SECTIONS.cheetah,
          SECTIONS.bartech_serial_number,
          SECTIONS.rear_license_plate,
          SECTIONS.oil_life_percentage,
          SECTIONS.van_registration,
          SECTIONS.insurance_card,
          SECTIONS.gas_card_number,
        ])
        return StatusDot(failure)
      },
    },
    {
      Header: 'Failing Sections',
      accessor: 'failingSections',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failingSections = Object.keys(state ?? {}).filter(
          (section) => (state ?? {})[section]?.coaching.needsCoaching,
        )
        return <>{failingSections.join(', ')}</>
      },
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <TableTopper
        isOpen={isOpen}
        onToggleClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Weekly DVIRs</TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={weeklyDvircolumns}
        data={audits}
        isLoading={false}
        onRowClick={() => {}}
        isOpen={isOpen}
        isDisplayMode={true}
      />
    </div>
  )
}

export { WeeklyDvirTable }
