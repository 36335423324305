import { createContext, useState } from 'react'
import { IAudit } from '../../../../../app/entities/Audit'
import {
  IAuditImage,
  IAuditSectionData,
  IAuditState,
  STATUS,
} from '../../types/audit'
import { fetchData } from '../../utils/fetch'
import {
  getAuditByObjectId,
  getDvirImages,
  getExampleImages,
  getInstallationImages,
  putAudit,
} from './api'
import { createOnSiteCancellationAuditData } from '../../../audits/components/audit-modal/flows/on-site-cancellation'
import { createCompletedInstallationAuditData } from '../../../audits/components/audit-modal/flows/completed-installation'
import { SECTIONS as COMPLETED_INSTALLATION_SECTIONS } from '../../constants/audit-completed-installation'
import { SECTIONS as ON_SITE_CANCELLATION_SECTIONS } from '../../constants/audit-on-site-cancellation'
import { createDailyDvirAuditData } from '../../../audits/components/audit-modal/flows/daily-dvir'
import { SECTIONS as DAILY_DVIR_SECTIONS } from '../../constants/audit-daily-dvir'
import { SECTIONS as WEEKLY_DVIR_SECTIONS } from '../../constants/audit-weekly-dvir'
import { createWeeklyDvirAuditData } from '../../../audits/components/audit-modal/flows/weekly-dvir'
import { IOrderVehicle } from '../../../../../app/entities/OrderVehicle'

interface IContextProps {
  auditData: IAudit | null
  setAuditData: Function
  auditSectionData: { [key: string]: IAuditSectionData }
  setAuditSectionData: Function
  technicianImages: IAuditImage[]
  setTechnicianImages: Function
  exampleImages: IAuditImage[]
  setExampleImages: Function
  auditStatus: STATUS | null
  setAuditStatus: Function
  auditState: IAuditState | null
  setAuditState: Function
  isAuditDataLoading: boolean
  setIsAuditDataLoading: Function
  orderVehicle: IOrderVehicle | null
  technician: string
  progress: string
  setProgress: Function
  sections: { [key: string]: string }
  setSections: Function
  clearAuditData: () => void
  saveAuditData: (
    newProgress: string,
    newAuditState: IAuditState | null,
  ) => Promise<void>
  queryAuditData: Function
}

interface IProviderProps {
  children: React.ReactNode
}

export const AuditContext = createContext<IContextProps>({} as IContextProps)

export function AuditContextProvider({ children }: IProviderProps) {
  const [auditData, setAuditData] = useState<IAudit | null>(null)
  const [auditSectionData, setAuditSectionData] = useState<{
    [key: string]: IAuditSectionData
  }>({})
  const [technician, setTechnician] = useState<string>('')
  const [technicianImages, setTechnicianImages] = useState<IAuditImage[]>([])
  const [exampleImages, setExampleImages] = useState<IAuditImage[]>([])
  const [auditStatus, setAuditStatus] = useState<STATUS | null>(null)
  const [auditState, setAuditState] = useState<IAuditState | null>(null)
  const [isAuditDataLoading, setIsAuditDataLoading] = useState<boolean>(false)
  const [progress, setProgress] = useState<string>('')
  const [sections, setSections] = useState<{ [key: string]: string }>({})
  const [orderVehicle, setOrderVehicle] = useState<IOrderVehicle | null>(null)

  function clearAuditData() {}

  async function queryAuditData(audtiObjectId: string) {
    setIsAuditDataLoading(true)
    try {
      const auditResults = await fetchData<IAudit>(
        getAuditByObjectId(audtiObjectId),
      )
      if (!auditResults) {
        throw new Error('No audit data found')
      }
      setAuditData(auditResults)
      const {
        status,
        orderVehicle: newOrderVehicle,
        dvir,
        state,
        progress: newProgress,
        technician,
      } = auditResults

      setAuditStatus(status)
      setAuditState(state)
      setTechnician(technician.name)
      setOrderVehicle(newOrderVehicle)

      switch (status) {
        case STATUS.COMPLETED_INSTALLATION: {
          const [newExampleImages, installationImages] = await Promise.all([
            fetchData<IAuditImage[]>(getExampleImages('installation')),
            fetchData<IAuditImage[]>(
              getInstallationImages(newOrderVehicle?.objectId || ''),
            ),
          ])
          setExampleImages(newExampleImages)
          setTechnicianImages(installationImages)
          setAuditSectionData(
            createCompletedInstallationAuditData(newOrderVehicle),
          )
          setProgress(
            newProgress ??
              COMPLETED_INSTALLATION_SECTIONS.pre_install_front_passenger,
          )
          setSections(COMPLETED_INSTALLATION_SECTIONS)
          break
        }
        case STATUS.ON_SITE_CANCELLATION: {
          const [newExampleImages, installationImages] = await Promise.all([
            fetchData<IAuditImage[]>(getExampleImages('installation')),
            fetchData<IAuditImage[]>(
              getInstallationImages(newOrderVehicle?.objectId || ''),
            ),
          ])
          setExampleImages(newExampleImages)
          setTechnicianImages(installationImages)
          const newOnSiteCancellationAuditData =
            createOnSiteCancellationAuditData(
              auditResults,
              newOrderVehicle,
              installationImages,
            )
          setAuditSectionData(newOnSiteCancellationAuditData)
          setProgress(
            newProgress ?? ON_SITE_CANCELLATION_SECTIONS.cancellation_condition,
          )
          setSections(ON_SITE_CANCELLATION_SECTIONS)
          break
        }
        case STATUS.DAILY_DVIR: {
          const [dvirImages, newExampleImages] = await Promise.all([
            fetchData<IAuditImage[]>(getDvirImages(dvir?.objectId || '')),
            fetchData<IAuditImage[]>(getExampleImages('dvir')),
          ])
          setExampleImages(newExampleImages)
          setTechnicianImages(dvirImages)
          const newDailyDvirAuditData = createDailyDvirAuditData(auditResults)
          setAuditSectionData(newDailyDvirAuditData)
          setProgress(newProgress ?? DAILY_DVIR_SECTIONS.van_external_images)
          setSections(DAILY_DVIR_SECTIONS)
          break
        }
        case STATUS.WEEKLY_DVIR: {
          const [dvirImages, newExampleImages] = await Promise.all([
            fetchData<IAuditImage[]>(getDvirImages(dvir?.objectId || '')),
            fetchData<IAuditImage[]>(getExampleImages('dvir')),
          ])
          setExampleImages(newExampleImages)
          setTechnicianImages(dvirImages)
          const newWeeklyDvirAuditData = createWeeklyDvirAuditData(dvir?.van)
          setAuditSectionData(newWeeklyDvirAuditData)
          setProgress(newProgress ?? WEEKLY_DVIR_SECTIONS.front_of_van)
          setSections(WEEKLY_DVIR_SECTIONS)
          break
        }
      }
    } catch (error) {
      console.error('Failed to fetch audit data:', error)
      throw error
    } finally {
      setIsAuditDataLoading(false)
    }
  }

  async function saveAuditData(
    newProgress: string,
    newAuditState: IAuditState | null,
  ) {
    if (!auditData) return
    auditData.progress = newProgress
    auditData.state = newAuditState
    await Promise.all([fetchData<IAudit>(putAudit(auditData))])
  }

  return (
    <AuditContext.Provider
      value={{
        auditData,
        setAuditData,
        auditSectionData,
        setAuditSectionData,
        technicianImages,
        setTechnicianImages,
        exampleImages,
        setExampleImages,
        auditStatus,
        auditState,
        setAuditState,
        setAuditStatus,
        isAuditDataLoading,
        setIsAuditDataLoading,
        progress,
        setProgress,
        sections,
        setSections,
        clearAuditData,
        saveAuditData,
        queryAuditData,
        technician,
        orderVehicle,
      }}
    >
      {children}
    </AuditContext.Provider>
  )
}
