import MenuItem from '@mui/material/MenuItem/MenuItem'
import TextField from '@mui/material/TextField/TextField'
import React from 'react'
import { getMondayOfISOWeek } from '../../utils/get-date-of-monday-of-week-number'

interface IWeeksDropDownComponentProps {
  setWeeks: Function
  weeks: number
  queryDate: Date
  setQueryDate: Function
}
function WeeksDropdown(props: IWeeksDropDownComponentProps) {
  const { setWeeks, weeks, setQueryDate } = props

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newWeek = parseInt(event.target.value)
    const date = getMondayOfISOWeek(newWeek)
    setQueryDate(date)
    setWeeks(event.target.value)
  }

  return (
    <TextField
      select
      label='Week Number'
      value={weeks}
      onChange={handleChange}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: '500px',
            },
          },
        },
      }}
      style={{ width: '100px' }}
    >
      {Array.from({ length: 52 }, (_, i) => i + 1).map((number) => (
        <MenuItem
          key={number}
          value={number}
        >
          {number}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default WeeksDropdown
