import { useEffect } from 'react'
import useFetch from '../../global/hooks/useFetchHook'
import { getSingleUserData } from '../api'
import CircularProgress from '@mui/material/CircularProgress'
import UserCreate from './UserCreate'
import { IUser, IUserPartial } from '../../../../app/entities/User'
interface IUserEditProps {
  email: string
  userData: IUserPartial
  setUserData: Function
  formError: boolean
  setFormError: Function
}
export default function UserEdit(props: IUserEditProps) {
  const { setUserData, userData, email, formError, setFormError } = props
  const { data, isLoading } = useFetch<IUser>(getSingleUserData(email))

  useEffect(() => {
    if (data) {
      setUserData({
        ...userData,
        objectId: data.objectId,
        name: data.name,
        email: data.email,
        role: data.role,
      })
    }
  }, [data])

  if (isLoading && !data) {
    return <CircularProgress />
  }
  return (
    <UserCreate
      userData={userData}
      setUserData={setUserData}
      formError={formError}
      setFormError={setFormError}
    />
  )
}
