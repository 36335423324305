import { ChangeEvent, useContext, useEffect, useState } from 'react'
import styles from '../styles.module.css'
import { TextField } from '@mui/material'
import { IUserPartial } from '../../../../app/entities/User'
import { isValidEmail } from '../../global/utils/formValidation'
import PrimaryCheckbox from '../../global/components/checkbox/Checkbox'
import RoleDropDown, { ROLE_DROPDOWN_ID } from '../common/RoleDropDown'
import { IRole, Role } from '../../../../app/entities/Role'
import { fetchData } from '../../global/utils/fetch'
import { getRoles } from '../../global/utils/roles/api'
import { ToastNotificationContext } from '../../global/context/toast-context/ToastNotificationContext'

interface IUserCreateProps {
  userData: IUserPartial
  setUserData: Function
  formError: boolean
  setFormError: Function
}

export default function UserCreate(props: IUserCreateProps) {
  const { userData, setUserData, formError, setFormError } = props
  const { name, email, role } = userData

  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const [rolesArray, setRolesArray] = useState<IRole[]>([])
  async function fetchRoleInformation() {
    const res = await fetchData<any>(getRoles(false))
    const { count, items: roles } = res
    setRolesArray(roles)
  }
  useEffect(() => {
    fetchRoleInformation()
  }, [])

  function handleChangeForm(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target
    let updatedData = { ...userData }

    const userChangedRole = name === ROLE_DROPDOWN_ID
    // handle Role changes differently as the Role dropdown only
    // contains the role name, but the user object requires the Role object
    if (userChangedRole) {
      const updatedRole = rolesArray.find(({ name }) => name === value)
      if (!updatedRole) {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: 'Could not find role! Please contact support.',
          severity: 'error',
        })
        return
      }

      updatedData['role'] = updatedRole as Role
    }

    if (name === 'email' && !isValidEmail(value)) {
      setFormError(true)
    } else {
      setFormError(false)
    }

    setUserData(updatedData)
  }

  return (
    <div className={styles.formContainer}>
      <TextField
        value={name}
        onChange={handleChangeForm}
        label='Name'
        name='name'
        fullWidth
      />
      <TextField
        value={email}
        onChange={handleChangeForm}
        label='Email'
        name='email'
        error={formError}
        helperText={formError && 'Please enter a valid email address'}
        fullWidth
      />
      <RoleDropDown
        initialValue={role?.name ?? ''}
        onChange={handleChangeForm}
        label='Role'
      />
    </div>
  )
}
