import { IVan } from '../../../../../../app/entities/Van'
import {
  BALANCING_CUP_CONDITION_DEFECTS,
  BARTEC_SERIAL_NUMBER_KEY,
  FIRE_EXTINGUISHER_DEFECTS,
  GAS_CARD_NUMBER_KEY,
  IMAGE_DEFECTS,
  INSURANCE_CARD_DEFECTS,
  IN_SERVICE_DATE_CHEETAH_KEY,
  OIL_LIFE_PERCENTAGE_KEY,
  PHOTO_INTERIOR_DEFECTS,
  PROMPT_DEFECT,
  QUESTIONS,
  REAR_LICENSE_PLATE_KEY,
  SECTIONS,
  SERIAL_NUMBER_CHEETAH_KEY,
  TITLES,
  TORQUE_WRENCH_WINDOW_DEFECTS,
  TREAD_WORK_SHOE_DEFECTS,
  VAN_DAMAGE_KEY,
  VAN_INTERIOR_WORK_AREA_DEFECTS,
  VAN_REGISTRATION_DEFECTS,
} from '../../../../global/constants/audit-weekly-dvir'
import {
  VIR_DAILY_PHOTOS,
  VIR_WEEKLY_PHOTOS,
} from '../../../../global/constants/virs'
import { IAuditSectionData } from '../../../../global/types/audit'

function createWeeklyDvirAuditData(van: IVan | undefined): {
  [key: string]: IAuditSectionData
} {
  const vanLicensePlate = van?.licensePlate ?? ''
  const bartechSerialNumber = van?.bartechSerialNumber ?? ''
  const cheetahSerialNumber = van?.cheetahSerialNumber ?? ''
  const cheetahInServiceDate = van?.cheetahInServiceDate ?? ''
  const gasCardLastFiveDigits = van?.gasCardLastFiveDigits ?? ''
  const vanRegistrationEndDate = van?.registrationEndDate

  let isVanRegistrationSoonToBeExpired = false
  let isVanRegistationExpired = false
  if (vanRegistrationEndDate) {
    const today = new Date()
    const vanRegistrationDate = new Date(vanRegistrationEndDate)
    const thirtyDaysFromToday = new Date(today)
    thirtyDaysFromToday.setDate(thirtyDaysFromToday.getDate() - 30)
    isVanRegistationExpired = today > vanRegistrationDate
    isVanRegistrationSoonToBeExpired =
      thirtyDaysFromToday < vanRegistrationDate && vanRegistrationDate < today
  }

  return {
    [SECTIONS.front_of_van]: {
      title: TITLES[SECTIONS.front_of_van],
      imageTypes: [VIR_DAILY_PHOTOS.FRONT_OF_VAN],
      question: QUESTIONS[SECTIONS.front_of_van],
      prompt: {
        [VAN_DAMAGE_KEY]: {
          value: 'N/A Technician did not record damage',
          textProps: { type: 'text', multiline: true, rows: 6 },
        },
      },
      defects: {
        [PROMPT_DEFECT.needsReview]: false,
      },
    },
    [SECTIONS.passenger_side_interior]: {
      title: TITLES[SECTIONS.passenger_side_interior],
      imageTypes: [VIR_WEEKLY_PHOTOS.PASSENGER_SIDE_INTERIOR],
      question: QUESTIONS[SECTIONS.passenger_side_interior],
      defects: {
        [PHOTO_INTERIOR_DEFECTS.dirty]: false,
        [PHOTO_INTERIOR_DEFECTS.doorClosed]: false,
        [PHOTO_INTERIOR_DEFECTS.noPictureWrongPicture]: false,
      },
    },
    [SECTIONS.passenger_van_exterior]: {
      title: TITLES[SECTIONS.passenger_van_exterior],
      imageTypes: [VIR_WEEKLY_PHOTOS.PASSENGER_VAN_EXTERIOR],
      question: QUESTIONS[SECTIONS.passenger_van_exterior],
      prompt: {
        [VAN_DAMAGE_KEY]: {
          value: 'N/A Technician did not record damage',
          textProps: { type: 'text', multiline: true, rows: 6 },
        },
      },
      defects: {
        [PROMPT_DEFECT.needsReview]: false,
      },
    },
    [SECTIONS.van_interior_work_area]: {
      title: TITLES[SECTIONS.van_interior_work_area],
      imageTypes: [VIR_WEEKLY_PHOTOS.VAN_INTERIOR_WORK_AREA],
      question: QUESTIONS[SECTIONS.van_interior_work_area],
      defects: {
        [VAN_INTERIOR_WORK_AREA_DEFECTS.missingEquipment]: false,
        [VAN_INTERIOR_WORK_AREA_DEFECTS.dirty]: false,
        [VAN_INTERIOR_WORK_AREA_DEFECTS.damaged]: false,
      },
    },
    [SECTIONS.fire_extinguisher]: {
      title: TITLES[SECTIONS.fire_extinguisher],
      imageTypes: [
        VIR_WEEKLY_PHOTOS.FIRE_EXTINGUISHER_DATE,
        VIR_WEEKLY_PHOTOS.FIRE_EXTINGUISHER_GAUGE,
      ],
      question: QUESTIONS[SECTIONS.fire_extinguisher],
      defects: {
        [FIRE_EXTINGUISHER_DEFECTS.expiredNoDate]: false,
        [FIRE_EXTINGUISHER_DEFECTS.missingEquipment]: false,
        [FIRE_EXTINGUISHER_DEFECTS.emptyTank]: false,
        [FIRE_EXTINGUISHER_DEFECTS.damaged]: false,
        [FIRE_EXTINGUISHER_DEFECTS.noPhoto]: false,
        [FIRE_EXTINGUISHER_DEFECTS.blurryBadPhoto]: false,
        [FIRE_EXTINGUISHER_DEFECTS.wrongPhoto]: false,
      },
    },
    [SECTIONS.cheetah]: {
      title: TITLES[SECTIONS.cheetah],
      imageTypes: [
        VIR_WEEKLY_PHOTOS.SERIAL_NUMBER_CHEETAH,
        VIR_WEEKLY_PHOTOS.MANUFACTURE_DATE_CHEETAH,
      ],
      question: QUESTIONS[SECTIONS.cheetah],
      prompt: {
        [SERIAL_NUMBER_CHEETAH_KEY]: {
          value: cheetahSerialNumber,
          textProps: { type: 'text', multiline: false, rows: 1 },
        },
        [IN_SERVICE_DATE_CHEETAH_KEY]: {
          value: cheetahInServiceDate,
          textProps: { type: 'text', multiline: false, rows: 1 },
        },
      },
      defects: {
        [PROMPT_DEFECT.needsReview]: false,
      },
    },
    [SECTIONS.bartech_serial_number]: {
      title: TITLES[SECTIONS.bartech_serial_number],
      imageTypes: [VIR_WEEKLY_PHOTOS.BARTEC_SERIAL_NUMBER],
      question: QUESTIONS[SECTIONS.bartech_serial_number],
      prompt: {
        [BARTEC_SERIAL_NUMBER_KEY]: {
          value: bartechSerialNumber,
          textProps: { type: 'text', multiline: false, rows: 1 },
        },
      },
      defects: {
        [PROMPT_DEFECT.needsReview]: false,
      },
    },
    [SECTIONS.torque_wrench_window]: {
      title: TITLES[SECTIONS.torque_wrench_window],
      imageTypes: [VIR_WEEKLY_PHOTOS.TORQUE_WRENCH_WINDOW],
      question: QUESTIONS[SECTIONS.torque_wrench_window],
      defects: {
        [TORQUE_WRENCH_WINDOW_DEFECTS.dirty]: false,
        [TORQUE_WRENCH_WINDOW_DEFECTS.noPhoto]: false,
        [TORQUE_WRENCH_WINDOW_DEFECTS.blurryBadPhoto]: false,
        [TORQUE_WRENCH_WINDOW_DEFECTS.wrongPhoto]: false,
      },
    },
    [SECTIONS.balancing_cup_condition]: {
      title: TITLES[SECTIONS.balancing_cup_condition],
      imageTypes: [VIR_WEEKLY_PHOTOS.BALANCING_CUP_CONDITION],
      question: QUESTIONS[SECTIONS.balancing_cup_condition],
      defects: {
        [BALANCING_CUP_CONDITION_DEFECTS.smallCupMissing]: false,
        [BALANCING_CUP_CONDITION_DEFECTS.largeCupMissing]: false,
        [BALANCING_CUP_CONDITION_DEFECTS.rubberProtectorRingDamagedMissing]:
          false,
        [BALANCING_CUP_CONDITION_DEFECTS.noPhoto]: false,
        [BALANCING_CUP_CONDITION_DEFECTS.blurryBadPhoto]: false,
        [BALANCING_CUP_CONDITION_DEFECTS.wrongPhoto]: false,
      },
    },
    [SECTIONS.rear_of_van]: {
      title: TITLES[SECTIONS.rear_of_van],
      imageTypes: [VIR_DAILY_PHOTOS.REAR_OF_VAN],
      question: QUESTIONS[SECTIONS.rear_of_van],
      prompt: {
        [VAN_DAMAGE_KEY]: {
          value: 'N/A Technician did not record damage',
          textProps: { type: 'text', multiline: true, rows: 6 },
        },
      },
      defects: {
        [PROMPT_DEFECT.needsReview]: false,
      },
    },
    [SECTIONS.rear_license_plate]: {
      title: TITLES[SECTIONS.rear_license_plate],
      imageTypes: [
        VIR_WEEKLY_PHOTOS.REAR_LICENSE_PLATE,
        VIR_WEEKLY_PHOTOS.FRONT_LICENSE_PLATE,
      ],
      question: QUESTIONS[SECTIONS.rear_license_plate],
      prompt: {
        [REAR_LICENSE_PLATE_KEY]: {
          value: vanLicensePlate,
          textProps: { type: 'text', multiline: false, rows: 1 },
        },
      },
      defects: {
        [PROMPT_DEFECT.needsReview]: false,
      },
    },
    [SECTIONS.rear_interior_cargo_area]: {
      title: TITLES[SECTIONS.rear_interior_cargo_area],
      imageTypes: [VIR_WEEKLY_PHOTOS.REAR_INTERIOR_CARGO_AREA],
      question: QUESTIONS[SECTIONS.rear_interior_cargo_area],
      prompt: {
        [VAN_DAMAGE_KEY]: {
          value: 'N/A Technician did not record damage',
          textProps: { type: 'text', multiline: true, rows: 6 },
        },
      },
      defects: {
        [PROMPT_DEFECT.needsReview]: false,
      },
    },
    [SECTIONS.driver_van_exterior]: {
      title: TITLES[SECTIONS.driver_van_exterior],
      imageTypes: [VIR_WEEKLY_PHOTOS.DRIVER_VAN_EXTERIOR],
      question: QUESTIONS[SECTIONS.driver_van_exterior],
      prompt: {
        [VAN_DAMAGE_KEY]: {
          value: 'N/A Technician did not record damage',
          textProps: { type: 'text', multiline: true, rows: 6 },
        },
      },
      defects: {
        [PROMPT_DEFECT.needsReview]: false,
      },
    },
    [SECTIONS.driver_side_interior]: {
      title: TITLES[SECTIONS.driver_side_interior],
      imageTypes: [VIR_WEEKLY_PHOTOS.DRIVER_SIDE_INTERIOR],
      question: QUESTIONS[SECTIONS.driver_side_interior],
      defects: {
        [PHOTO_INTERIOR_DEFECTS.dirty]: false,
        [PHOTO_INTERIOR_DEFECTS.doorClosed]: false,
        [PHOTO_INTERIOR_DEFECTS.noPictureWrongPicture]: false,
      },
    },
    [SECTIONS.tread_work_shoe]: {
      title: TITLES[SECTIONS.tread_work_shoe],
      imageTypes: [VIR_WEEKLY_PHOTOS.TREAD_WORK_SHOE],
      question: QUESTIONS[SECTIONS.tread_work_shoe],
      defects: {
        [TREAD_WORK_SHOE_DEFECTS.no]: false,
        [TREAD_WORK_SHOE_DEFECTS.needsReplacement]: false,
      },
    },
    [SECTIONS.oil_life_percentage]: {
      title: TITLES[SECTIONS.oil_life_percentage],
      imageTypes: [VIR_WEEKLY_PHOTOS.REMAINING_OIL_LIFE],
      question: QUESTIONS[SECTIONS.oil_life_percentage],
      prompt: {
        [OIL_LIFE_PERCENTAGE_KEY]: {
          value: '',
          textProps: { type: 'number', multiline: false, rows: 1 },
        },
      },
      defects: {
        [PROMPT_DEFECT.needsReview]: false,
      },
    },
    [SECTIONS.van_registration]: {
      title: TITLES[SECTIONS.van_registration],
      imageTypes: [VIR_WEEKLY_PHOTOS.VAN_REGISTRATION],
      question: QUESTIONS[SECTIONS.van_registration],
      defects: {
        [VAN_REGISTRATION_DEFECTS.noPictureWrongPicture]: false,
        [VAN_REGISTRATION_DEFECTS.missing]: false,
        [VAN_REGISTRATION_DEFECTS.outOfDate]: isVanRegistationExpired,
        [VAN_REGISTRATION_DEFECTS.soonToBeExpired]:
          isVanRegistrationSoonToBeExpired,
      },
    },
    [SECTIONS.insurance_card]: {
      title: TITLES[SECTIONS.insurance_card],
      imageTypes: [VIR_WEEKLY_PHOTOS.INSURANCE_CARD],
      question: QUESTIONS[SECTIONS.insurance_card],
      defects: {
        [INSURANCE_CARD_DEFECTS.noPictureWrongPicture]: false,
        [INSURANCE_CARD_DEFECTS.missing]: false,
        [INSURANCE_CARD_DEFECTS.outOfDate]: false,
        [INSURANCE_CARD_DEFECTS.soonToBeExpired]: false,
      },
    },
    [SECTIONS.gas_card_number]: {
      title: TITLES[SECTIONS.gas_card_number],
      imageTypes: [VIR_WEEKLY_PHOTOS.GAS_CARD_NUMBER],
      question: QUESTIONS[SECTIONS.gas_card_number],
      prompt: {
        [GAS_CARD_NUMBER_KEY]: {
          value: gasCardLastFiveDigits,
          textProps: { type: 'number', multiline: false, rows: 1 },
        },
      },
      defects: {
        [PROMPT_DEFECT.needsReview]: false,
      },
    },
    [SECTIONS.first_aid_kit]: {
      title: TITLES[SECTIONS.first_aid_kit],
      imageTypes: [VIR_WEEKLY_PHOTOS.FIRST_AID_KIT],
      question: QUESTIONS[SECTIONS.first_aid_kit],
      defects: {
        [IMAGE_DEFECTS.noPhoto]: false,
        [IMAGE_DEFECTS.blurryBadPhoto]: false,
        [IMAGE_DEFECTS.wrongPhoto]: false,
      },
    },
    [SECTIONS.ansi_osha_warning_triangles]: {
      title: TITLES[SECTIONS.ansi_osha_warning_triangles],
      imageTypes: [VIR_WEEKLY_PHOTOS.ANSI_OSHA_WARNING_TRIANGLES],
      question: QUESTIONS[SECTIONS.ansi_osha_warning_triangles],
      defects: {
        [IMAGE_DEFECTS.noPhoto]: false,
        [IMAGE_DEFECTS.blurryBadPhoto]: false,
        [IMAGE_DEFECTS.wrongPhoto]: false,
      },
    },
  }
}

export { createWeeklyDvirAuditData }
