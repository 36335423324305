import { SchedulingPerformanceLocationData } from '../SchedulingPerformance'

enum FIXES {
  CALL_CUSTOMER_CARE = 'CALL CUSTOMER CARE',
  INCREASE_DRIVETIME = 'INCREASE DRIVETIME',
}

const tooltip: { [key in FIXES]: string } = {
  'CALL CUSTOMER CARE':
    'Notify the Customer Care Manager to investigate the TR website performance.',
  'INCREASE DRIVETIME': [
    'Increase the Drivetime minutes for the location to allow customers within ',
    'the current AS400 zip code area to schedule. Investigate if other routing ',
    'reasons may be preventing certain areas from scheduling.',
  ].join(''),
}

export function getFixForLocation(
  schedulingPerformanceLocation: SchedulingPerformanceLocationData,
  thresholdValuesJson: {
    integratedSchedulerRateThreshold: number
    averageScheduleLeadTimeThreshold: number
  },
): [string, string] | undefined {
  const integratedSchedulerFlag =
    Number(
      schedulingPerformanceLocation.integratedSchedulerRate.replace(/%/g, ''),
    ) < (thresholdValuesJson.integratedSchedulerRateThreshold || 0.5) ||
    isNaN(
      Number(
        schedulingPerformanceLocation.integratedSchedulerRate.replace(/%/g, ''),
      ),
    )
  const drivetimeZeroesFlag =
    Number(schedulingPerformanceLocation.driveTimeZeroes) > 0
  const scheduleFullZeroesFlag =
    Number(schedulingPerformanceLocation.scheduleFullZeroes) > 0
  const averageScheduleLeadTimeFlag =
    Number(schedulingPerformanceLocation.averageScheduleLeadTime) >
    (thresholdValuesJson.averageScheduleLeadTimeThreshold ?? 7)
  const sevenDayPercentBookedEightyFlag =
    Number(schedulingPerformanceLocation.sevenDayPercentBooked) > 80

  const fixes: FIXES[] = []

  if (integratedSchedulerFlag) {
    fixes.push(FIXES.CALL_CUSTOMER_CARE)
  }

  if (drivetimeZeroesFlag) {
    if (!scheduleFullZeroesFlag) {
      if (!averageScheduleLeadTimeFlag && !sevenDayPercentBookedEightyFlag) {
        fixes.push(FIXES.INCREASE_DRIVETIME)
      }
    }
  }

  const fixText = fixes.map((fix) => fix).join(' ')
  const tooltipText = fixes.map((fix) => tooltip[fix]).join(' ')
  return [fixText, tooltipText]
}
