import { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import CircularProgress from '@mui/material/CircularProgress'
import ModalContent from './ModalContent'
import { AuditContext } from '../../../global/context/audit-context/AuditContext'
import { ToastNotificationContext } from '../../../global/context/toast-context/ToastNotificationContext'
import { AUDIT_PROGRESS } from '../../../global/constants/audit'
import { IAuditState } from '../../../global/types/audit'
import { checkIsCoached } from '../../utils/checkIsCoached'

interface ICoachingModalProps {
  isOpen: boolean
  setIsOpen: Function
  refetchIndex: number
  setRefetchIndex: Function
}

export default function CoachingModal(props: ICoachingModalProps) {
  const { isOpen, setIsOpen, setRefetchIndex, refetchIndex } = props
  const { isAuditDataLoading, auditState, setAuditState, saveAuditData } =
    useContext(AuditContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  if (!auditState || isAuditDataLoading) {
    return (
      <div>
        <Modal open={isOpen}>
          <Box className={styles.coachingModal}>
            <div className={styles.progressContainer}>
              <CircularProgress />
            </div>
          </Box>
        </Modal>
      </div>
    )
  }

  const initialCoachingSection = Object.entries(auditState).find(
    ([_, value]) => !value.coaching.coached && value.coaching.needsCoaching,
  )?.[0]

  const [section, setSection] = useState<string>(initialCoachingSection || '')

  async function handleClosed(
    newAuditState: IAuditState | null,
  ): Promise<void> {
    setIsOpen(false)
    const isCoached = checkIsCoached(newAuditState)
    const savedOrCoached = isCoached ? 'coached' : 'saved'
    try {
      await saveAuditData(
        isCoached ? AUDIT_PROGRESS.coached : AUDIT_PROGRESS.needsCoaching,
        newAuditState,
      )
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `Successfully ${savedOrCoached} audit.`,
        severity: 'success',
      })
    } catch (error) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error saving the audit.',
        severity: 'Error',
      })
    } finally {
      setRefetchIndex(refetchIndex + 1)
    }
  }

  function handleNextSection(newAuditState: IAuditState | null): void {
    if (!auditState) return
    const sectionsNeedingCoaching = Object.entries(auditState)
      .filter(([_, value]) => value.coaching.needsCoaching)
      .map(([key, _]) => key)

    const isLastSection =
      sectionsNeedingCoaching.indexOf(section) ===
      sectionsNeedingCoaching.length - 1
    if (isLastSection) {
      handleClosed(newAuditState)
    } else {
      const nextSection =
        sectionsNeedingCoaching[sectionsNeedingCoaching.indexOf(section) + 1]
      setSection(nextSection)
      setAuditState(newAuditState)
    }
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => handleClosed(auditState)}
      >
        <Box className={styles.coachingModal}>
          {(isAuditDataLoading || !auditState) && (
            <div className={styles.progressContainer}>
              <CircularProgress />
            </div>
          )}
          {!isAuditDataLoading && auditState && (
            <ModalContent
              section={section}
              handleNextSection={handleNextSection}
              handleClose={handleClosed}
            />
          )}
        </Box>
      </Modal>
    </div>
  )
}
